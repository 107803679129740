// EmailActionHandler.js
import { useEffect, useState } from "react";
import { getAuth, applyActionCode } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";

const EmailActionHandler = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Track messages and errors
  const [message, setMessage] = useState("Processing...");
  const [error, setError] = useState(null);
  // Flag to know if user needs to sign in because no active session exists
  const [requiresSignIn, setRequiresSignIn] = useState(false);
  // Track active user state using onAuthStateChanged
  const [activeUser, setActiveUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setActiveUser(user);
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get("mode");
    const oobCode = params.get("oobCode");

    if (mode === "verifyEmail" && oobCode) {
      applyActionCode(auth, oobCode)
        .then(() => {
          // If an active user exists, reload them to update verification status.
          if (activeUser) {
            return activeUser.reload().then(() => {
              setMessage("You've been verified!");
              // After a brief delay, redirect to /subpage.
              setTimeout(() => {
                navigate("/subpage");
              }, 3000);
            });
          } else {
            // No active user; likely the link was opened in a new tab.
            setMessage("You've been verified! If you still have your original tab open, continue there. Otherwise, sign in below");
            setRequiresSignIn(true);
            return Promise.resolve();
          }
        })
        .catch((err) => {
          setError("Verification failed: " + err.message);
        });
    } else {
      setError("Invalid action.");
    }
  }, [auth, location, navigate, activeUser]);

  return (
    <Box textAlign="center" mt={4}>
      {error ? (
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      ) : (
        <Typography variant="h6">
          {message}
        </Typography>
      )}
      {requiresSignIn ? (
        <Button variant="contained" onClick={() => navigate("/signin")} sx={{ mt: 2 }}>
        Sign In
      </Button>
      
      ) : (
        activeUser && (
          <Button
            variant="contained"
            onClick={() => navigate("/subpage")}
            sx={{ mt: 2 }}
          >
            Go to Dashboard
          </Button>
        )
      )}
    </Box>
  );
};

export default EmailActionHandler;
