// EventCustomizationForm.jsx
import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Typography,
  IconButton,
  Grid,
  Input,
  Slider,
  Box,
  Card,
  CardMedia,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { PhotoCamera, ExpandMore } from '@mui/icons-material';
import { fonts, backgroundColors } from '../utils/fontsandthemes';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const EventCustomizationForm = ({
  eventDetails = {},
  onNext,
  onBack,
  initialData = {},
  eventId,
  editMode = false,
  hidePreviewOnMobile = false, // if true, hide the internal preview (used for mobile)
  onChange, // optional callback that receives updated customization data
}) => {
  // ---------------------------------------------------------
  // State Initialization for Customization Fields
  // ---------------------------------------------------------
  const [backgroundColor, setBackgroundColor] = useState(initialData.backgroundColor || '#ffffff');
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverPhotoUrl, setCoverPhotoUrl] = useState(
    initialData?.coverPhotoUrl || 'https://via.placeholder.com/150'
  );

  const [titleFont, setTitleFont] = useState(initialData?.titleFont || fonts[0].name);
  const [titleFontSize, setTitleFontSize] = useState(initialData?.titleFontSize || '20px');
  const [titleFontColor, setTitleFontColor] = useState(initialData?.titleFontColor || '#000000');
  const [titleShadow, setTitleShadow] = useState(initialData?.titleShadow || '0px 0px 5px rgba(0, 0, 0, 0.5)');
  const [titleBold, setTitleBold] = useState(initialData?.titleBold || false);
  const [titleGlow, setTitleGlow] = useState(initialData?.titleGlow || '0px 0px 10px rgba(255, 255, 255, 0.75)');
  const [glowColor, setGlowColor] = useState(initialData?.glowColor || '#ffffff');

  const [dateFont, setDateFont] = useState(initialData?.dateFont || fonts[0].name);
  const [dateFontSize, setDateFontSize] = useState(initialData?.dateFontSize || '14px');
  const [dateFontColor, setDateFontColor] = useState(initialData?.dateFontColor || '#000000');
  const [dateBold, setDateBold] = useState(initialData?.dateBold || false);

  const [welcomeFont, setWelcomeFont] = useState(initialData?.welcomeFont || fonts[0].name);
  const [welcomeFontSize, setWelcomeFontSize] = useState(initialData?.welcomeFontSize || '16px');
  const [welcomeFontColor, setWelcomeFontColor] = useState(initialData?.welcomeFontColor || '#000000');
  const [welcomeBold, setWelcomeBold] = useState(initialData?.welcomeBold || false);

  const [buttonColor, setButtonColor] = useState(initialData?.buttonColor || '#1976d2');
  const [buttonGlowColor, setButtonGlowColor] = useState(initialData?.buttonGlowColor || '#007aff');
  const [glowIntensity, setGlowIntensity] = useState(initialData?.glowIntensity || 15);

  // ---------------------------------------------------------
  // Visibility Toggles (except upload button which is always shown)
  // ---------------------------------------------------------
  const [showTitle, setShowTitle] = useState(
    initialData?.showTitle !== undefined ? initialData.showTitle : true
  );
  const [showDate, setShowDate] = useState(
    initialData?.showDate !== undefined ? initialData.showDate : true
  );
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(
    initialData?.showWelcomeMessage !== undefined ? initialData.showWelcomeMessage : true
  );

  // Toggles the palette for background colors
  const [showColorOptions, setShowColorOptions] = useState(!!initialData?.showColorOptions);

  // Dummy placeholder images for preview (no longer used in desktop preview)
  const placeholderImages = [
    'https://firebasestorage.googleapis.com/v0/b/scan-cap.appspot.com/o/Customization%2FScreenshot%202024-10-07%20140919.jpg?alt=media&token=96b811f6-81ed-4103-b8a4-aea745e9a30b',
  ];

  // New state: controls the upload popup visibility (desktop)
  const [uploadPopupOpen, setUploadPopupOpen] = useState(false);

  // ---------------------------------------------------------
  // Sync state from initialData on mount only
  // ---------------------------------------------------------
  useEffect(() => {
    setBackgroundColor(initialData?.backgroundColor || '#ffffff');
    setTitleFont(initialData?.titleFont || fonts[0].name);
    setCoverPhotoUrl(initialData?.coverPhotoUrl || 'https://via.placeholder.com/150');
    setTitleFontSize(initialData?.titleFontSize || '20px');
    setTitleFontColor(initialData?.titleFontColor || '#000000');
    setTitleBold(initialData?.titleBold || false);
    setTitleShadow(initialData?.titleShadow || '0px 0px 5px rgba(0, 0, 0, 0.5)');
    setTitleGlow(initialData?.titleGlow || '0px 0px 10px rgba(255, 255, 255, 0.75)');
    setDateFont(initialData?.dateFont || fonts[0].name);
    setDateFontSize(initialData?.dateFontSize || '14px');
    setDateFontColor(initialData?.dateFontColor || '#000000');
    setDateBold(initialData?.dateBold || false);
    setWelcomeFont(initialData?.welcomeFont || fonts[0].name);
    setWelcomeFontSize(initialData?.welcomeFontSize || '16px');
    setWelcomeFontColor(initialData?.welcomeFontColor || '#000000');
    setWelcomeBold(initialData?.welcomeBold || false);
    setButtonColor(initialData?.buttonColor || '#1976d2');
    setButtonGlowColor(initialData?.buttonGlowColor || '#007aff');
    setGlowIntensity(initialData?.glowIntensity || 15);

    // Visibility (except upload button which is always shown)
    setShowTitle(initialData?.showTitle !== undefined ? initialData.showTitle : true);
    setShowDate(initialData?.showDate !== undefined ? initialData.showDate : true);
    setShowWelcomeMessage(initialData?.showWelcomeMessage !== undefined ? initialData.showWelcomeMessage : true);
  }, []); // Run once on mount

  // ---------------------------------------------------------
  // Helper: Generic Update for a Field with onChange Callback
  // ---------------------------------------------------------
  const handleCustomizationUpdate = (field, value) => {
    switch (field) {
      case 'backgroundColor':
        setBackgroundColor(value);
        break;
      case 'titleFont':
        setTitleFont(value);
        break;
      case 'titleFontSize':
        setTitleFontSize(value);
        break;
      case 'titleFontColor':
        setTitleFontColor(value);
        break;
      case 'titleShadow':
        setTitleShadow(value);
        break;
      case 'titleBold':
        setTitleBold(value);
        break;
      case 'titleGlow':
        setTitleGlow(value);
        break;
      case 'dateFont':
        setDateFont(value);
        break;
      case 'dateFontSize':
        setDateFontSize(value);
        break;
      case 'dateFontColor':
        setDateFontColor(value);
        break;
      case 'dateBold':
        setDateBold(value);
        break;
      case 'welcomeFont':
        setWelcomeFont(value);
        break;
      case 'welcomeFontSize':
        setWelcomeFontSize(value);
        break;
      case 'welcomeFontColor':
        setWelcomeFontColor(value);
        break;
      case 'welcomeBold':
        setWelcomeBold(value);
        break;
      case 'buttonColor':
        setButtonColor(value);
        break;
      case 'buttonGlowColor':
        setButtonGlowColor(value);
        break;
      case 'glowIntensity':
        setGlowIntensity(value);
        break;
      // Visibility toggles (except upload button which is always shown)
      case 'showTitle':
        setShowTitle(value);
        break;
      case 'showDate':
        setShowDate(value);
        break;
      case 'showWelcomeMessage':
        setShowWelcomeMessage(value);
        break;
      default:
        break;
    }

    const updatedData = {
      backgroundColor: field === 'backgroundColor' ? value : backgroundColor,
      coverPhotoUrl,
      titleFont: field === 'titleFont' ? value : titleFont,
      titleFontSize: field === 'titleFontSize' ? value : titleFontSize,
      titleFontColor: field === 'titleFontColor' ? value : titleFontColor,
      titleShadow: field === 'titleShadow' ? value : titleShadow,
      titleBold: field === 'titleBold' ? value : titleBold,
      titleGlow: field === 'titleGlow' ? value : titleGlow,
      dateFont: field === 'dateFont' ? value : dateFont,
      dateFontSize: field === 'dateFontSize' ? value : dateFontSize,
      dateFontColor: field === 'dateFontColor' ? value : dateFontColor,
      dateBold: field === 'dateBold' ? value : dateBold,
      welcomeFont: field === 'welcomeFont' ? value : welcomeFont,
      welcomeFontSize: field === 'welcomeFontSize' ? value : welcomeFontSize,
      welcomeFontColor: field === 'welcomeFontColor' ? value : welcomeFontColor,
      welcomeBold: field === 'welcomeBold' ? value : welcomeBold,
      buttonColor: field === 'buttonColor' ? value : buttonColor,
      // Compute buttonGlow inline from the current glowIntensity and buttonGlowColor
      buttonGlow:
        field === 'buttonGlowColor'
          ? `0px 0px ${glowIntensity}px ${value}`
          : field === 'glowIntensity'
          ? `0px 0px ${value}px ${buttonGlowColor}`
          : `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
      buttonGlowColor: field === 'buttonGlowColor' ? value : buttonGlowColor,
      glowIntensity: field === 'glowIntensity' ? value : glowIntensity,
      // Visibility (except upload button which is always shown)
      showTitle: field === 'showTitle' ? value : showTitle,
      showDate: field === 'showDate' ? value : showDate,
      showWelcomeMessage: field === 'showWelcomeMessage' ? value : showWelcomeMessage,
    };

    if (onChange) onChange(updatedData);
  };

  // ---------------------------------------------------------
  // File Upload Handler for Cover Photo
  // ---------------------------------------------------------
  const handleCoverPhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverPhoto(file);
      const storage = getStorage();
      const storageRef = ref(storage, `coverPhotos/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setCoverPhotoUrl(url);
      const updatedData = {
        backgroundColor,
        coverPhotoUrl: url,
        titleFont,
        titleFontSize,
        titleFontColor,
        titleShadow,
        titleBold,
        titleGlow,
        dateFont,
        dateFontSize,
        dateFontColor,
        dateBold,
        welcomeFont,
        welcomeFontSize,
        welcomeFontColor,
        welcomeBold,
        buttonColor,
        buttonGlow: `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
        buttonGlowColor,
        glowIntensity,
        showTitle,
        showDate,
        showWelcomeMessage,
      };
      if (onChange) onChange(updatedData);
    }
  };

  // ---------------------------------------------------------
  // Finalize and proceed to next step
  // ---------------------------------------------------------
  const handleNext = async () => {
    const finalData = {
      backgroundColor,
      coverPhotoUrl,
      titleFont,
      titleFontSize,
      titleFontColor,
      titleShadow,
      titleBold,
      titleGlow,
      dateFont,
      dateFontSize,
      dateFontColor,
      dateBold,
      welcomeFont,
      welcomeFontSize,
      welcomeFontColor,
      welcomeBold,
      buttonColor,
      buttonGlow: `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
      buttonGlowColor,
      glowIntensity,
      showTitle,
      showDate,
      showWelcomeMessage,
    };
    onNext(finalData);
  };

  // ---------------------------------------------------------
  // Render a Photo Placeholder for Desktop Version
  // ---------------------------------------------------------
  const renderPhotoPlaceholder = () => (
    <Box
      sx={{
        marginTop: '20px',
        width: '80%',
        height: '250px',
        mx: 'auto',
        borderRadius: '8px',
        background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#666',
        textAlign: 'center',
        p: 2,
      }}
    >
      Your photos will appear here
    </Box>
  );

  // ============================================================
  // Render the Component UI
  // ============================================================
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Left Column: Customization Inputs */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1">Upload Cover Photo:</Typography>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-cover-photo"
            type="file"
            onChange={handleCoverPhotoChange}
          />
          <label htmlFor="upload-cover-photo">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              sx={{
                backgroundColor: '#1976d2',
                color: '#fff',
                borderRadius: '50%',
                boxShadow: '0 0 8px rgba(0,0,0,0.3)',
                padding: '8px',
                '&:hover': {
                  backgroundColor: '#1565c0',
                  transform: 'scale(1.1)',
                },
                transition: 'all 0.3s ease',
              }}
            >
              <PhotoCamera />
            </IconButton>
          </label>

          {/* Background Customization */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Background Customization
            </Typography>
            <IconButton
              component="label"
              color="primary"
              onClick={() => setShowColorOptions(!showColorOptions)}
              sx={{
                position: 'relative',
                width: 50,
                height: 50,
                borderRadius: '50%',
                marginTop: 1,
                cursor: 'pointer',
                boxShadow: '0 0 5px rgba(0,0,0,0.3)',
                '&:hover': { transform: 'scale(1.1)' },
                transition: 'transform 0.3s ease',
              }}
            >
              <Box
                component="svg"
                sx={{ width: 32, height: 32 }}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill="#D2B48C"
                  d="M12 2C7.03 2 2.5 5.53 2.5 10.5s4.53 8.5 9.5 8.5c.5 0 .99-.04 1.48-.12l.63.14.55.82c.54.81 1.47 1.3 2.43 1.3.83 0 1.5-.67 1.5-1.5V16c0-.55.45-1 1-1h.5c3.04 0 5.5-2.46 5.5-5.5C21.5 5.03 17.52 2 12 2z"
                />
                <circle cx="6.5" cy="8" r="1" fill="#FF0000" />
                <circle cx="10.5" cy="6" r="1" fill="#00FF00" />
                <circle cx="14.5" cy="6" r="1" fill="#0000FF" />
                <circle cx="18.5" cy="8" r="1" fill="#FFFF00" />
              </Box>
              <Input
                type="color"
                value={backgroundColor}
                onChange={(e) => handleCustomizationUpdate('backgroundColor', e.target.value)}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0,
                  cursor: 'pointer',
                }}
              />
            </IconButton>
          </Box>

          {/* Visibility Section */}
          <Box sx={{ marginTop: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Visibility
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showTitle}
                    onChange={(e) => handleCustomizationUpdate('showTitle', e.target.checked)}
                  />
                }
                label="Show Title"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showDate}
                    onChange={(e) => handleCustomizationUpdate('showDate', e.target.checked)}
                  />
                }
                label="Show Date"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showWelcomeMessage}
                    onChange={(e) => handleCustomizationUpdate('showWelcomeMessage', e.target.checked)}
                  />
                }
                label="Show Welcome Message"
              />
            </FormGroup>
          </Box>

          {/* Title Customization */}
          <Box sx={{ marginBottom: 3, marginTop: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Title Customization
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
              <Select
                value={titleFont}
                onChange={(e) => handleCustomizationUpdate('titleFont', e.target.value)}
                sx={{ width: '150px', '&:hover': { backgroundColor: '#f0f0f0' } }}
              >
                {fonts.map((fontOption, index) => (
                  <MenuItem key={index} value={fontOption.name} style={{ fontFamily: fontOption.name }}>
                    {fontOption.name}
                  </MenuItem>
                ))}
              </Select>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const newSize = `${Math.max(12, parseInt(titleFontSize) - 1)}px`;
                    setTitleFontSize(newSize);
                    const updatedData = {
                      backgroundColor,
                      coverPhotoUrl,
                      titleFont,
                      titleFontSize: newSize,
                      titleFontColor,
                      titleShadow,
                      titleBold,
                      titleGlow,
                      dateFont,
                      dateFontSize,
                      dateFontColor,
                      dateBold,
                      welcomeFont,
                      welcomeFontSize,
                      welcomeFontColor,
                      welcomeBold,
                      buttonColor,
                      buttonGlow: `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
                      buttonGlowColor,
                      glowIntensity,
                      showTitle,
                      showDate,
                      showWelcomeMessage,
                    };
                    if (onChange) onChange(updatedData);
                  }}
                  sx={{ minWidth: '36px', '&:hover': { backgroundColor: '#f0f0f0' } }}
                >
                  -
                </Button>
                <Typography>{parseInt(titleFontSize)}</Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const newSize = `${Math.min(50, parseInt(titleFontSize) + 1)}px`;
                    setTitleFontSize(newSize);
                    const updatedData = {
                      backgroundColor,
                      coverPhotoUrl,
                      titleFont,
                      titleFontSize: newSize,
                      titleFontColor,
                      titleShadow,
                      titleBold,
                      titleGlow,
                      dateFont,
                      dateFontSize,
                      dateFontColor,
                      dateBold,
                      welcomeFont,
                      welcomeFontSize,
                      welcomeFontColor,
                      welcomeBold,
                      buttonColor,
                      buttonGlow: `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
                      buttonGlowColor,
                      glowIntensity,
                      showTitle,
                      showDate,
                      showWelcomeMessage,
                    };
                    if (onChange) onChange(updatedData);
                  }}
                  sx={{ minWidth: '36px', '&:hover': { backgroundColor: '#f0f0f0' } }}
                >
                  +
                </Button>
              </Box>
              <IconButton
                component="label"
                sx={{
                  position: 'relative',
                  '&:hover': {
                    '&::after': {
                      content: '"Change Font Color"',
                      position: 'absolute',
                      top: '-24px',
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      fontSize: '12px',
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '28px',
                    background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    '&:hover': { transform: 'scale(1.1)', transition: 'all 0.3s ease' },
                  }}
                >
                  A
                </Typography>
                <Input
                  type="color"
                  value={titleFontColor}
                  onChange={(e) => handleCustomizationUpdate('titleFontColor', e.target.value)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
              <Typography
                onClick={() => handleCustomizationUpdate('titleBold', !titleBold)}
                sx={{
                  fontWeight: 'bold',
                  fontSize: '28px',
                  cursor: 'pointer',
                  textDecoration: titleBold ? 'underline' : 'none',
                  '&:hover': { color: '#1976d2', transition: 'all 0.3s ease' },
                }}
              >
                B
              </Typography>
            </Box>
          </Box>

          {/* Title Shadow Intensity */}
          <Typography variant="body1" sx={{ marginTop: '10px' }}>
            Title Shadow Intensity:
          </Typography>
          <Slider
            value={parseInt(titleShadow.split(' ')[2]) || 5}
            onChange={(e, newValue) => {
              const newShadow = `0px 0px ${newValue}px rgba(0, 0, 0, 0.5)`;
              setTitleShadow(newShadow);
              const updatedData = {
                backgroundColor,
                coverPhotoUrl,
                titleFont,
                titleFontSize,
                titleFontColor,
                titleShadow: newShadow,
                titleBold,
                titleGlow,
                dateFont,
                dateFontSize,
                dateFontColor,
                dateBold,
                welcomeFont,
                welcomeFontSize,
                welcomeFontColor,
                welcomeBold,
                buttonColor,
                buttonGlow: `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
                buttonGlowColor,
                glowIntensity,
                showTitle,
                showDate,
                showWelcomeMessage,
              };
              if (onChange) onChange(updatedData);
            }}
            aria-labelledby="shadow-intensity-slider"
            valueLabelDisplay="auto"
            min={0}
            max={20}
          />

          {/* Welcome Message Customization */}
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Welcome Message Customization
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
              <Select
                value={welcomeFont}
                onChange={(e) => handleCustomizationUpdate('welcomeFont', e.target.value)}
                sx={{ width: '150px', '&:hover': { backgroundColor: '#f0f0f0' } }}
              >
                {fonts.map((fontOption, index) => (
                  <MenuItem key={index} value={fontOption.name} style={{ fontFamily: fontOption.name }}>
                    {fontOption.name}
                  </MenuItem>
                ))}
              </Select>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const newSize = `${Math.max(12, parseInt(welcomeFontSize) - 1)}px`;
                    setWelcomeFontSize(newSize);
                    const updatedData = {
                      backgroundColor,
                      coverPhotoUrl,
                      titleFont,
                      titleFontSize,
                      titleFontColor,
                      titleShadow,
                      titleBold,
                      titleGlow,
                      dateFont,
                      dateFontSize,
                      dateFontColor,
                      dateBold,
                      welcomeFont,
                      welcomeFontSize: newSize,
                      welcomeFontColor,
                      welcomeBold,
                      buttonColor,
                      buttonGlow: `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
                      buttonGlowColor,
                      glowIntensity,
                      showTitle,
                      showDate,
                      showWelcomeMessage,
                    };
                    if (onChange) onChange(updatedData);
                  }}
                  sx={{ minWidth: '36px', '&:hover': { backgroundColor: '#f0f0f0' } }}
                >
                  -
                </Button>
                <Typography>{parseInt(welcomeFontSize)}</Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const newSize = `${Math.min(50, parseInt(welcomeFontSize) + 1)}px`;
                    setWelcomeFontSize(newSize);
                    const updatedData = {
                      backgroundColor,
                      coverPhotoUrl,
                      titleFont,
                      titleFontSize,
                      titleFontColor,
                      titleShadow,
                      titleBold,
                      titleGlow,
                      dateFont,
                      dateFontSize,
                      dateFontColor,
                      dateBold,
                      welcomeFont,
                      welcomeFontSize: newSize,
                      welcomeFontColor,
                      welcomeBold,
                      buttonColor,
                      buttonGlow: `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
                      buttonGlowColor,
                      glowIntensity,
                      showTitle,
                      showDate,
                      showWelcomeMessage,
                    };
                    if (onChange) onChange(updatedData);
                  }}
                  sx={{ minWidth: '36px', '&:hover': { backgroundColor: '#f0f0f0' } }}
                >
                  +
                </Button>
              </Box>
              <IconButton component="label" sx={{ position: 'relative' }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '28px',
                    background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    cursor: 'pointer',
                  }}
                >
                  A
                </Typography>
                <Input
                  type="color"
                  value={welcomeFontColor}
                  onChange={(e) => handleCustomizationUpdate('welcomeFontColor', e.target.value)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
              <Typography
                onClick={() => handleCustomizationUpdate('welcomeBold', !welcomeBold)}
                sx={{
                  fontWeight: 'bold',
                  fontSize: '28px',
                  cursor: 'pointer',
                  textDecoration: welcomeBold ? 'underline' : 'none',
                  '&:hover': { color: '#1976d2', transition: 'all 0.3s ease' },
                }}
              >
                B
              </Typography>
            </Box>
          </Box>

          {/* Date Customization */}
          <Box sx={{ marginBottom: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Date Customization
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
              <Select
                value={dateFont}
                onChange={(e) => handleCustomizationUpdate('dateFont', e.target.value)}
                sx={{ width: '150px', '&:hover': { backgroundColor: '#f0f0f0' } }}
              >
                {fonts.map((fontOption, index) => (
                  <MenuItem key={index} value={fontOption.name} style={{ fontFamily: fontOption.name }}>
                    {fontOption.name}
                  </MenuItem>
                ))}
              </Select>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const newSize = `${Math.max(12, parseInt(dateFontSize) - 1)}px`;
                    setDateFontSize(newSize);
                    const updatedData = {
                      backgroundColor,
                      coverPhotoUrl,
                      titleFont,
                      titleFontSize,
                      titleFontColor,
                      titleShadow,
                      titleBold,
                      titleGlow,
                      dateFont,
                      dateFontSize: newSize,
                      dateFontColor,
                      dateBold,
                      welcomeFont,
                      welcomeFontSize,
                      welcomeFontColor,
                      welcomeBold,
                      buttonColor,
                      buttonGlow: `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
                      buttonGlowColor,
                      glowIntensity,
                      showTitle,
                      showDate,
                      showWelcomeMessage,
                    };
                    if (onChange) onChange(updatedData);
                  }}
                  sx={{ minWidth: '36px', '&:hover': { backgroundColor: '#f0f0f0' } }}
                >
                  -
                </Button>
                <Typography>{parseInt(dateFontSize)}</Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const newSize = `${Math.min(50, parseInt(dateFontSize) + 1)}px`;
                    setDateFontSize(newSize);
                    const updatedData = {
                      backgroundColor,
                      coverPhotoUrl,
                      titleFont,
                      titleFontSize,
                      titleFontColor,
                      titleShadow,
                      titleBold,
                      titleGlow,
                      dateFont,
                      dateFontSize: newSize,
                      dateFontColor,
                      dateBold,
                      welcomeFont,
                      welcomeFontSize,
                      welcomeFontColor,
                      welcomeBold,
                      buttonColor,
                      buttonGlow: `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
                      buttonGlowColor,
                      glowIntensity,
                      showTitle,
                      showDate,
                      showWelcomeMessage,
                    };
                    if (onChange) onChange(updatedData);
                  }}
                  sx={{ minWidth: '36px', '&:hover': { backgroundColor: '#f0f0f0' } }}
                >
                  +
                </Button>
              </Box>
              <IconButton component="label" sx={{ position: 'relative' }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '28px',
                    background: 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    cursor: 'pointer',
                  }}
                >
                  A
                </Typography>
                <Input
                  type="color"
                  value={dateFontColor}
                  onChange={(e) => handleCustomizationUpdate('dateFontColor', e.target.value)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
              <Typography
                onClick={() => handleCustomizationUpdate('dateBold', !dateBold)}
                sx={{
                  fontWeight: 'bold',
                  fontSize: '28px',
                  cursor: 'pointer',
                  textDecoration: dateBold ? 'underline' : 'none',
                  '&:hover': { color: '#1976d2', transition: 'all 0.3s ease' },
                }}
              >
                B
              </Typography>
            </Box>
          </Box>

          {/* Upload Button Customization */}
          <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
            Upload Button Customization
          </Typography>
          <Typography variant="body1" sx={{ marginTop: '10px' }}>
            Upload Button Color:
          </Typography>
          <IconButton
            component="label"
            color="primary"
            sx={{
              position: 'relative',
              width: 50,
              height: 50,
              borderRadius: '50%',
              cursor: 'pointer',
              boxShadow: '0 0 5px rgba(0,0,0,0.3)',
              '&:hover': { transform: 'scale(1.1)' },
              transition: 'transform 0.3s ease',
            }}
          >
            <Box
              component="svg"
              sx={{ width: 32, height: 32 }}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill="#D2B48C"
                d="M12 2C7.03 2 2.5 5.53 2.5 10.5s4.53 8.5 9.5 8.5c.5 0 .99-.04 1.48-.12l.63.14.55.82c.54.81 1.47 1.3 2.43 1.3.83 0 1.5-.67 1.5-1.5V16c0-.55.45-1 1-1h.5c3.04 0 5.5-2.46 5.5-5.5C21.5 5.03 17.52 2 12 2z"
              />
              <circle cx="6.5" cy="8" r="1" fill="#FF0000" />
              <circle cx="10.5" cy="6" r="1" fill="#00FF00" />
              <circle cx="14.5" cy="6" r="1" fill="#0000FF" />
              <circle cx="18.5" cy="8" r="1" fill="#FFFF00" />
            </Box>
            <Input
              type="color"
              value={buttonColor}
              onChange={(e) => handleCustomizationUpdate('buttonColor', e.target.value)}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 0,
                cursor: 'pointer',
              }}
            />
          </IconButton>
          <Typography variant="body1" sx={{ marginTop: '10px' }}>
            Upload Button Glow Intensity:
          </Typography>
          <Slider
            value={glowIntensity}
            onChange={(e, newValue) => handleCustomizationUpdate('glowIntensity', newValue)}
            aria-labelledby="glow-intensity-slider"
            valueLabelDisplay="auto"
            min={5}
            max={50}
          />
          <Typography variant="body1" sx={{ marginTop: '10px' }}>
            Upload Button Glow Color:
          </Typography>
          <IconButton
            component="label"
            color="primary"
            sx={{
              position: 'relative',
              width: 50,
              height: 50,
              borderRadius: '50%',
              cursor: 'pointer',
              boxShadow: '0 0 5px rgba(0,0,0,0.3)',
              '&:hover': { transform: 'scale(1.1)' },
              transition: 'transform 0.3s ease',
            }}
          >
            <Box
              component="svg"
              sx={{ width: 32, height: 32 }}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill="#D2B48C"
                d="M12 2C7.03 2 2.5 5.53 2.5 10.5s4.53 8.5 9.5 8.5c.5 0 .99-.04 1.48-.12l.63.14.55.82c.54.81 1.47 1.3 2.43 1.3.83 0 1.5-.67 1.5-1.5V16c0-.55.45-1 1-1h.5c3.04 0 5.5-2.46 5.5-5.5C21.5 5.03 17.52 2 12 2z"
              />
              <circle cx="6.5" cy="8" r="1" fill="#FF0000" />
              <circle cx="10.5" cy="6" r="1" fill="#00FF00" />
              <circle cx="14.5" cy="6" r="1" fill="#0000FF" />
              <circle cx="18.5" cy="8" r="1" fill="#FFFF00" />
            </Box>
            <Input
              type="color"
              value={buttonGlowColor}
              onChange={(e) => handleCustomizationUpdate('buttonGlowColor', e.target.value)}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 0,
                cursor: 'pointer',
              }}
            />
          </IconButton>
        </Grid>

        {/* Right Column: Preview Section */}
        <Grid item xs={12} md={6}>
          {!hidePreviewOnMobile && (
            <>
              <Typography variant="h5" component="h2" gutterBottom>
                Event Preview
              </Typography>
              <Box
                sx={{
                  transform: { xs: 'scale(0.8)', md: 'scale(1)' },
                  transformOrigin: 'top center',
                  width: { xs: '300px', md: '375px' },
                  height: { xs: '600px', md: '812px' },
                  border: '16px solid #000',
                  borderRadius: '36px',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 'auto',
                  background: '#ffffff',
                  position: 'relative',
                  overflow: 'hidden',
                  flexDirection: 'column',
                  padding: '0px',
                }}
              >
                <Box
                  sx={{
                    width: '60px',
                    height: '5px',
                    backgroundColor: '#c0c0c0',
                    borderRadius: '10px',
                    position: 'absolute',
                    top: '18px',
                  }}
                />
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    border: '2px solid #c0c0c0',
                    position: 'absolute',
                    top: '10px',
                    right: '95px',
                    backgroundColor: '#fff',
                  }}
                />
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                    padding: '20px',
                    paddingTop: '80px',
                    background: backgroundColor,
                  }}
                >
                  <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={coverPhotoUrl}
                      alt="Cover"
                      style={{
                        borderRadius: '50%',
                        width: '120px',
                        height: '120px',
                        objectFit: 'cover',
                        border: '5px solid #e0e0e0',
                      }}
                    />
                  </div>

                  {/* Conditionally render Title */}
                  {showTitle && (
                    <Typography
                      variant="h5"
                      style={{
                        fontFamily: `${titleFont}, sans-serif`,
                        fontSize: titleFontSize,
                        color: titleFontColor,
                        textShadow: `${titleGlow}, ${titleShadow}`,
                        fontWeight: titleBold ? 'bold' : 'normal',
                        textAlign: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      {eventDetails?.eventName || 'Untitled Event'}
                    </Typography>
                  )}

                  {/* Conditionally render Date */}
                  {showDate && (
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: `${dateFont}, sans-serif`,
                        fontSize: dateFontSize,
                        color: dateFontColor,
                        fontWeight: dateBold ? 'bold' : 'normal',
                        textAlign: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      {eventDetails.eventDate}
                    </Typography>
                  )}

                  {/* Conditionally render Welcome Message */}
                  {showWelcomeMessage && eventDetails.welcomeMessage && (
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: `${welcomeFont}, sans-serif`,
                        fontSize: welcomeFontSize,
                        color: welcomeFontColor,
                        fontWeight: welcomeBold ? 'bold' : 'normal',
                        textAlign: 'center',
                        marginBottom: '20px',
                      }}
                    >
                      {eventDetails.welcomeMessage}
                    </Typography>
                  )}

                  {/* Always render Upload Button */}
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button
                      variant="contained"
                      onClick={() => setUploadPopupOpen(true)}
                      style={{
                        backgroundColor: buttonColor,
                        marginBottom: '10px',
                        width: '80%',
                        boxShadow: `0px 0px ${glowIntensity}px ${buttonGlowColor}`,
                      }}
                    >
                      Upload Photos/Videos
                    </Button>
                    <Typography variant="caption" color="textSecondary" align="center">
                      (This is the button guests will use to upload media during the event)
                    </Typography>
                  </Box>

                  {/* Instead of a photo grid, show a placeholder message */}
                  {renderPhotoPlaceholder()}
                </Box>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next
        </Button>
      </Box>

      {/* Upload Popup Dialog for Desktop */}
      <Dialog open={uploadPopupOpen} onClose={() => setUploadPopupOpen(false)}>
        <DialogTitle>Upload Photos/Videos</DialogTitle>
        <DialogContent>
          <Typography>
            This popup is where the upload functionality would be implemented.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadPopupOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EventCustomizationForm;
