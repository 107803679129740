export const fonts = [
  // Classic and Serif Fonts
  { name: 'Arial' },
  { name: 'Georgia' },
  { name: 'Times New Roman' },
  { name: 'Garamond' },
  { name: 'Palatino Linotype' },
  { name: 'Baskerville' },
  { name: 'Merriweather' },
  { name: 'Playfair Display' },
  { name: 'EB Garamond' },

  // Modern and Sans-Serif Fonts
  { name: 'Roboto' },
  { name: 'Lato' },
  { name: 'Montserrat' },
  { name: 'Open Sans' },
  { name: 'PT Sans' },
  { name: 'Raleway' },
  { name: 'Futura' },
  { name: 'Helvetica Neue' },
  { name: 'Nunito' },
  { name: 'Poppins' },
  { name: 'Work Sans' },

  // Script and Decorative Fonts
  { name: 'Great Vibes' },
  { name: 'Dancing Script' },
  { name: 'Pacifico' },
  { name: 'Lobster' },
  { name: 'Satisfy' },
  { name: 'Alex Brush' },
  { name: 'Parisienne' },
  { name: 'Allura' },

  // Luxury Cursive Fonts
  { name: 'Lucida Calligraphy' },
  { name: 'Edwardian Script ITC' },
  { name: 'Bickham Script Pro' },
  { name: 'Adine Kirnberg' },
  { name: 'Magnolia Script' },

  // Display and Headline Fonts
  { name: 'Impact' },
  { name: 'Bebas Neue' },
  { name: 'Oswald' },
  { name: 'Anton' },
  { name: 'Cinzel' },
  { name: 'Copperplate' },
  { name: 'Trajan Pro' },

  // Luxury and Formal Fonts
  { name: 'Cormorant Garamond' },
  { name: 'Didot' },
  { name: 'Serif Gothic' },
  { name: 'Charter' },
  { name: 'Optima' },
  { name: 'Caslon' },

  // Fun and Casual Fonts
  { name: 'Comic Sans' },
  { name: 'Chalkboard' },
  { name: 'Varela Round' },
  { name: 'Kristen ITC' },
  { name: 'Permanent Marker' },
  { name: 'Fredericka the Great' },

  // Monospaced Fonts
  { name: 'Courier New' },
  { name: 'Lucida Console' },
  { name: 'Source Code Pro' },
  { name: 'Inconsolata' }
];

// Background color options for selection
export const backgroundColors = [
  { name: 'White', color: '#ffffff' },
  { name: 'Black', color: '#000000' },
  { name: 'Red', color: '#ff0000' },
  { name: 'Blue', color: '#0000ff' },
  { name: 'Green', color: '#00ff00' },
  { name: 'Yellow', color: '#ffff00' },
  { name: 'Pink', color: '#ffc0cb' },
  { name: 'Orange', color: '#ffa500' },
  { name: 'Purple', color: '#800080' },
  { name: 'Teal', color: '#008080' },
  { name: 'Custom', color: 'custom' } // Special case for user to pick any color
];
