import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
  Backdrop,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { auth } from '../firebase/firebaseConfig';
import { getApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  onSnapshot
} from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import { CheckCircle, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

// FAQ Component
const FAQ = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        paddingY: 6,
        backgroundColor: '#f9f9f9',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        marginTop: 6
      }}
    >
      {/* Page Title */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 4,
          color: '#333'
        }}
      >
        Frequently Asked Questions
      </Typography>

      {/* Section: General Information */}
      <Box sx={{ marginBottom: 5 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'medium', color: '#555' }}
        >
          General Information
        </Typography>
        <Accordion
          sx={{
            border: 'none',
            borderRadius: 3,
            boxShadow: '0 2px 15px rgba(0,0,0,0.1)',
            marginBottom: 2
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>
              What is Elevate Event Studio?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
            Elevate Event Studio is your all-in-one photo collection solution. With one QR code or link, guests instantly upload their moments into a stunning live gallery — no login for guests, no chasing, no missing memories, just effortless magic
            !
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            border: 'none',
            borderRadius: 3,
            boxShadow: '0 2px 15px rgba(0,0,0,0.1)',
            marginBottom: 2
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>
              When is the QR code generated?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              The QR code is generated instantly when you create your live
              gallery, making it easy to share immediately with your guests.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Section: Gallery Customization */}
      <Box sx={{ marginBottom: 5 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'medium', color: '#555' }}
        >
          Gallery Customization
        </Typography>
        <Accordion
          sx={{
            border: 'none',
            borderRadius: 3,
            boxShadow: '0 2px 15px rgba(0,0,0,0.1)',
            marginBottom: 2
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>
              What can I customize in my live gallery?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
            Customize your gallery title, add a cover photo, and include a personalized welcome message. Choose your background, glow effects, and button colors to create a unique experience your guests will remember.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            border: 'none',
            borderRadius: 3,
            boxShadow: '0 2px 15px rgba(0,0,0,0.1)',
            marginBottom: 2
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>
              Can I use the QR code on promotional materials?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Yes, download the QR code and add it to posters, invitations, or
              any other promotional materials to easily invite guests to your
              live gallery.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            border: 'none',
            borderRadius: 3,
            boxShadow: '0 2px 15px rgba(0,0,0,0.1)',
            marginBottom: 2
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>
              Can I share the QR code via email?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Absolutely. Sharing the QR code via email is a quick and
              convenient way to invite your contacts to join your live gallery.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Section: Media Collection and Sharing */}
      <Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'medium', color: '#555' }}
        >
          Media Collection and Sharing
        </Typography>
        <Accordion
          sx={{
            border: 'none',
            borderRadius: 3,
            boxShadow: '0 2px 15px rgba(0,0,0,0.1)',
            marginBottom: 2
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>
              Why not use Google Drive or Dropbox for photo collection?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Although Google Drive and Dropbox are excellent for file storage,
              they don't provide the same level of control over uploads and
              permissions. Elevate Event Studio offers a dedicated, streamlined
              live gallery experience.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            border: 'none',
            borderRadius: 3,
            boxShadow: '0 2px 15px rgba(0,0,0,0.1)',
            marginBottom: 2
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>
              Why is email not ideal for photo collection?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Collecting photos via email can be cumbersome and disorganized.
              Our platform streamlines the process by offering a centralized,
              easy-to-manage live gallery.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            border: 'none',
            borderRadius: 3,
            boxShadow: '0 2px 15px rgba(0,0,0,0.1)',
            marginBottom: 2
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>
              Can users download and share photos?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Yes, users can easily download and share their favorite photos
              directly from the live gallery, ensuring memories are always at
              your fingertips.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
};

// Pricing plans for Subpage
const plans = [
  {
    name: 'Event Flash',
    price: '$3.99',
    description:
      'For up to 15 guests – great for casual celebrations and small get-togethers.',
    features: [
      'Custom fonts & glowing buttons',
      'Cover photo + welcome message',
      'Unique gallery link & QR code',
      'Centralized photo & video uploads',
      'Background customization'
    ],
    priceId: 'price_1R85uFRtiroMBcDI97u58KNs',
    isFreeTrial: false
  },
  {
    name: 'Event Glow-Up',
    price: '$9.99',
    description:
      'For up to 35 guests – best for birthdays, showers, or fun weekend events.',
    features: [
      'Custom fonts & glowing buttons',
      'Cover photo + welcome message',
      'Unique gallery link & QR code',
      'Centralized photo & video uploads',
      'Background customization'
    ],
    priceId: 'price_1QftFXRtiroMBcDIFhy8TSYQ',
    isFreeTrial: false
  },
  {
    name: 'Social Spark',
    price: '$29.99',
    description:
      'For up to 60 guests – milestone events like weddings, graduations, engagements, and more.',
    features: [
      'Custom fonts & glowing buttons',
      'Cover photo + welcome message',
      'Unique gallery link & QR code',
      'Centralized photo & video uploads',
      'Background customization'
    ],
    priceId: 'price_1R85VSRtiroMBcDIXv9u0P6q',
    isFreeTrial: false
  },
  {
    name: 'VIP Experience',
    price: '$49.99',
    description:
      'For up to 100 guests – excellent for weddings, graduations, engagements, and more.',
    features: [
      'Custom fonts & glowing buttons',
      'Cover photo + welcome message',
      'Unique gallery link & QR code',
      'Centralized photo & video uploads',
      'Background customization'
    ],
    priceId: 'price_1R85YsRtiroMBcDIi5iHkGUb',
    isFreeTrial: false
  },
  {
    name: 'Premium',
    price: '$99.99',
    description:
      'For up to 250 guests – ideal for large weddings, corporate events, or planners.',
    features: [
      'Custom fonts & glowing buttons',
      'Cover photo + welcome message',
      'Unique gallery link & QR code',
      'Centralized photo & video uploads',
      'Background customization'
    ],
    priceId: 'price_1R85wBRtiroMBcDIf4HpIbr7',
    isFreeTrial: false
  }
];

const Subpage = () => {
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const containerRef = useRef(null);

  // Preload Stripe for faster redirection
  useEffect(() => {
    const stripe = loadStripe(
      'pk_live_51PYc2DRtiroMBcDILn1UgHqdZwqK8DWTpioBHvfAGvQOHrQ0B8f9v4J9aXpUcsLyqkKvQwiPbPo6N2bZTdR549Zg00Le2nYE3R'
    );
    setStripePromise(stripe);
  }, []);

  useEffect(() => {
    // On mount, nudge scroll on mobile to force layout recalculation
    if (window.innerWidth < 600 && containerRef.current) {
      containerRef.current.scrollTop = 1;
      containerRef.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user && user.emailVerified) {
        setUserId(user.uid);
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.uid);
        const unsub = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            console.log('User data:', docSnap.data());
          }
        });
        return () => unsub();
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  // Local checkout function – uses payment intent logic for all plans and includes metadata
  const handleCheckoutLocal = async (priceId) => {
    setLoading(true);
    setError(null);
    try {
      if (!userId) {
        throw new Error('User is not authenticated');
      }
      const app = getApp();
      const db = getFirestore(app);
      const userDocRef = doc(db, 'users', userId);
      const sessionData = {
        price: priceId,
        success_url: 'https://elevateevent.co/eventdashboard',
        cancel_url: window.location.origin + '/cancel',
        allow_promotion_codes: true,
        mode: 'payment', // using payment intent for all plans now
        metadata: { firebase_uid: userId }
      };

      const sessionRef = await addDoc(
        collection(userDocRef, 'checkout_sessions'),
        sessionData
      );
      onSnapshot(sessionRef, async (doc) => {
        const data = doc.data();
        const stripeSessionId = data?.sessionId;
        if (stripeSessionId) {
          try {
            const stripe = await stripePromise;
            if (stripe) {
              await stripe.redirectToCheckout({ sessionId: stripeSessionId });
            }
          } catch (error) {
            setError(error.message);
          } finally {
            setLoading(false);
          }
        }
      });
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <Container
      ref={containerRef}
      maxWidth="lg"
      sx={{
        marginTop: { xs: '80px', md: '100px' },
        padding: { xs: 2, md: 4 },
        paddingBottom: '50px',
        minHeight: { xs: 'auto', md: 'calc(100vh - 100px)' },
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        align="center"
        gutterBottom
        sx={{
          color: '#000',
          fontWeight: 'bold',
          fontSize: { xs: '1.8rem', md: '2.4rem' }
        }}
      >
        Choose Your Plan
      </Typography>

      <Typography
        variant="h6"
        align="center"
        color="textSecondary"
        gutterBottom
        sx={{
          color: '#666',
          fontSize: { xs: '1rem', md: '1.25rem' },
          marginX: 'auto',
          maxWidth: 600,
          marginBottom: '2em'
        }}
      >
        Collect and share photos seamlessly with no attendee login required.
      </Typography>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{ marginTop: { xs: 2, md: 4 } }}
      >
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={12} md={4} key={index}>
            <Card
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                backgroundColor: '#fff',
                color: '#000',
                borderRadius: '16px',
                boxShadow: '0 6px 18px rgba(0, 0, 0, 0.06)',
                border: 'none',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 12px 24px rgba(0, 0, 0, 0.12)'
                },
                ...(plan.name === 'VIP Experience' && {
                  border: '2px solid #FFD700'
                })
              }}
            >
              {plan.name === 'VIP Experience' && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    backgroundColor: '#FFD700',
                    color: '#000',
                    fontWeight: 'bold',
                    px: 2,
                    py: 0.5,
                    borderRadius: '8px',
                    fontSize: '0.75rem',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)'
                  }}
                >
                  MOST POPULAR
                </Box>
              )}

              <CardContent
                sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '1.4rem', md: '1.75rem' },
                    marginBottom: '8px'
                  }}
                >
                  {plan.name}
                </Typography>

                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: '16px',
                    fontSize: { xs: '1rem', md: '1.25rem' },
                    fontWeight: '600',
                    color: '#2A73E8'
                  }}
                >
                  {plan.price}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: '20px',
                    fontSize: { xs: '0.95rem', md: '1.1rem' },
                    color: '#555'
                  }}
                >
                  {plan.description}
                </Typography>

                <Box component="ul" sx={{ padding: 0, listStyle: 'none', flexGrow: 1 }}>
                  {plan.features.map((feature, idx) => (
                    <Box
                      component="li"
                      key={idx}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px'
                      }}
                    >
                      <CheckCircle
                        sx={{
                          color: '#2A73E8',
                          marginRight: '8px',
                          fontSize: '1.3rem'
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ fontSize: { xs: '0.95rem', md: '1.05rem' }, color: '#333' }}
                      >
                        {feature}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </CardContent>

              <Box textAlign="center" mb={2}>
                <Button
                  variant="contained"
                  sx={{
                    background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)',
                    color: '#fff',
                    paddingY: 1.3,
                    paddingX: 3,
                    fontWeight: 'bold',
                    borderRadius: '8px',
                    fontSize: '1rem',
                    textTransform: 'uppercase',
                    '&:hover': {
                      background: 'linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)',
                      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)'
                    }
                  }}
                  onClick={() => handleCheckoutLocal(plan.priceId)}
                  disabled={!userId}
                >
                  {plan.isFreeTrial ? 'Start Trial' : 'Buy Now'}
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {error && (
        <Box mt={2}>
          <Typography variant="h6" align="center" color="error">
            An error occurred: {error}
          </Typography>
        </Box>
      )}

      {/* FAQ Section */}
      <FAQ />

      {/* Fullscreen Backdrop for loading */}
      <Backdrop
        open={loading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: '#fff',
          flexDirection: 'column'
        }}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Redirecting to checkout...
        </Typography>
      </Backdrop>
    </Container>
  );
};

export default Subpage;
