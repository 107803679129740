import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Elevate Event Studio Privacy Policy</h1>
      <p><strong>Effective Date:</strong> March, 18th 2025</p>

      <h2>1. Introduction</h2>
      <p>
        Elevate Event Studio ("we," "us," or "our") is committed to protecting the privacy of our users. 
        This Privacy Policy explains how we collect, use, and share your personal information when you 
        use our website, mobile applications, and services (collectively, the "Services").
      </p>

      <h2>2. Information We Collect</h2>
      <ul>
        <li><strong>Account Information:</strong> Name, email address, and password when you create an account.</li>
        <li><strong>Payment Information:</strong> Collected securely through Stripe; we do not store credit card details directly.</li>
        <li><strong>Event Information:</strong> Details you provide such as event name, date, time, location, etc.</li>
        <li><strong>Photo Content:</strong> Photos uploaded to your events.</li>
        <li><strong>Usage Information:</strong> Data such as pages visited, features used, and time spent on the Services.</li>
        <li><strong>Device Information:</strong> IP address, browser type, and operating system.</li>
        <li><strong>Cookies and Similar Technologies:</strong> Used to track activity and enhance user experience.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <ul>
        <li>Providing the Services, including event creation, photo storage, and payment processing.</li>
        <li>Improving the Services by analyzing user interactions.</li>
        <li>Communicating with you about your account or event updates.</li>
        <li>Marketing communications with your consent.</li>
        <li>Ensuring legal compliance and protecting against misuse.</li>
      </ul>

      <h2>4. How We Share Your Information</h2>
      <ul>
        <li><strong>Service Providers:</strong> Trusted partners like Stripe (payments), Firebase (storage), and Twilio (communications).</li>
        <li><strong>Event Attendees:</strong> Public event content may be accessible to attendees; you control privacy settings.</li>
        <li><strong>Legal Authorities:</strong> Disclosures may occur if required by law.</li>
        <li><strong>Business Transfers:</strong> Data may be transferred in the case of a merger or acquisition.</li>
      </ul>

      <h2>5. Your Choices</h2>
      <ul>
        <li>You can access and update your account details through your settings.</li>
        <li>Opt out of marketing communications at any time.</li>
        <li>Manage cookie preferences via browser settings.</li>
        <li>Request data deletion through customer support.</li>
      </ul>

      <h2>6. Data Security</h2>
      <p>
        We take reasonable security measures, including encryption of data in transit and at rest. However, no system is 
        entirely secure, and we encourage you to use strong passwords to protect your account.
      </p>

      <h2>7. Children’s Privacy</h2>
      <p>
        Our Services are not intended for children under 13. If we discover we have collected information from a child under 13, 
        we will promptly delete it. If hosting events involving children, you are responsible for obtaining parental consent.
      </p>

      <h2>8. Third-Party APIs</h2>
      <ul>
        <li><strong>Stripe:</strong> Payment processing. See their policy <a href="https://stripe.com/privacy">here</a>.</li>
        <li><strong>Twilio:</strong> SMS and email notifications. See their policy <a href="https://www.twilio.com/legal/privacy">here</a>.</li>
        <li><strong>Firebase:</strong> Data storage and database services. See their policy <a href="https://firebase.google.com/support/privacy">here</a>.</li> 
      </ul>

      <h2>9. State-Specific Requirements</h2>
      <p>
        We comply with applicable state laws including California's CCPA, Colorado's CPA, and others. For full compliance details, contact us directly.
      </p>

      <h2>10. No International Data Transfers</h2>
      <p>
        All data collected and processed by Elevate Event Studio is stored and processed within the United States.
      </p>

      <h2>11. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy periodically. We will notify you of material changes by posting the revised policy 
        on this page with the updated effective date.
      </p>

      <h2>12. Contact Us</h2>
      <p>
        If you have questions about this Privacy Policy, please contact us at support@elevateevent.co
      </p>
    </div>
  );
};

export default PrivacyPolicy;
