import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Tooltip,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu'; // For mobile hamburger icon
import Sidebar from './components/Sidebar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import './App.css';
import Home from './routes/Home';
import EventCreationForm from './components/EventCreationForm';
import EventPage from './components/EventPage';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import FAQ from './components/FAQ';
import Subpage from './components/Subpage';
import PrivateRoute from './components/PrivateRoute';
import TermsAndConditions from './components/TermsAndConditions';
import PrivatePolicy from './components/PrivatePolicy';
import EventDashboard from './components/EventDashboard';
import QRCodeTemplates from './components/QRCodeTemplates';
import Docs from './components/Docs';
import logo from './assets/logo.svg';
import EventDetailsForm from './components/EventDetailsForm';
import EventCustomizationForm from './components/EventCustomizationForm';
import EventPreviewForm from './components/EventPreviewForm';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpSupport from './components/HelpSupport';
// Import the EmailActionHandler component
import EmailActionHandler from './components/EmailActionHandler';

function App() {
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}

const AppWrapper = () => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // Unified anchor state
  const [menuType, setMenuType] = useState(null); // Tracks which menu is open ('resources' or 'account')
  const [mobileOpen, setMobileOpen] = useState(false); // State for mobile sidebar drawer
  const [portalUrl, setPortalUrl] = useState(null); // For prefetched customer portal URL
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Define the routes where you want to show the hamburger menu on mobile.
  // Added '/qrcode-templates' and '/docs' to include the template and document pages.
  const sidebarRoutes = ['/create-event', '/edit-event', '/eventdashboard', '/qrcode-templates', '/docs'];
  const isSidebarRoute = sidebarRoutes.some((route) => location.pathname.startsWith(route));

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        setCurrentUser(user);
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        setHasSubscription(userDoc.exists() && userDoc.data().subscriptionStatus === 'active');
      } else {
        setCurrentUser(null);
        setHasSubscription(false);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Listen for the pageshow event to reset the redirection state when returning to the app.
  useEffect(() => {
    const handlePageShow = () => {
      setIsRedirecting(false);
    };
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  // Prefetch the customer portal URL function
  const prefetchPortalUrl = async () => {
    try {
      const functions = getFunctions();
      const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
      const { data } = await createPortalLink({ returnUrl: window.location.origin });
      setPortalUrl(data.url);
    } catch (error) {
      console.error('Error prefetching portal URL:', error);
    }
  };

  // Prefetch the portal URL as soon as the user is logged in
  useEffect(() => {
    if (currentUser) {
      prefetchPortalUrl();
    }
  }, [currentUser]);

  // Updated sendToCustomerPortal function with prefetch fallback
  const sendToCustomerPortal = async () => {
    try {
      setIsRedirecting(true); // Show the spinner
      if (portalUrl) {
        window.location.assign(portalUrl);
      } else {
        // Fallback: fetch if not already prefetched
        const functions = getFunctions();
        const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
        const { data } = await createPortalLink({ returnUrl: window.location.origin });
        window.location.assign(data.url);
      }
    } catch (error) {
      console.error('Error redirecting to Stripe customer portal:', error);
      setIsRedirecting(false);
    }
  };

  const handleMenuClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setMenuType(type);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuType(null);
  };

  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut()
      .then(() => navigate('/', { replace: true }))
      .catch((error) => console.error('Error signing out:', error));
    handleMenuClose();
  };

  const sendToCustomerPortalOnHover = () => {
    // For mobile, onMouseEnter may not fire,
    // but it's kept as a fallback to prefetch if not already done.
    if (!portalUrl) {
      prefetchPortalUrl();
    }
  };

  const isPublicEvent = location.pathname.startsWith('/events/');

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      {/* Spinner overlay for Stripe redirection */}
      {isRedirecting && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <CircularProgress size={60} sx={{ color: '#fff' }} />
        </Box>
      )}

      {/* Render Sidebar on desktop or mobile if toggled */}
      {!isPublicEvent && currentUser && (isMobile 
        ? <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} isAuthenticated={!!currentUser} />
        : <Sidebar isAuthenticated={!!currentUser} />
      )}

      <Box sx={{ flexGrow: 1, overflowX: 'hidden' }}>
        {!isPublicEvent && (
          <AppBar
            position="fixed"
            sx={{
              top: 0,
              left: 0,
              right: 0,
              background: location.pathname === '/signup' || location.pathname === '/signin' || location.pathname === '/subpage'
                ? 'linear-gradient(90deg, #2A73E8 0%, #B3D8FF 100%)'
                : currentUser
                ? 'linear-gradient(to right, #4a90e2, #98c93c)'
                : 'white',
              color: 'black',
              boxShadow: 'none',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <Toolbar>
              {/* On mobile, conditionally show hamburger menu or logo */}
              {isMobile ? (
                isSidebarRoute ? (
                  <IconButton edge="start" color="inherit" onClick={() => setMobileOpen(!mobileOpen)}>
                    <MenuIcon sx={{ fontSize: '32px' }} />
                  </IconButton>
                ) : (
                  <IconButton edge="start" color="inherit" component={Link} to="/">
                    <Box
                      component="img"
                      src={logo}
                      alt="Logo"
                      sx={{ height: { xs: '50px', md: '80px' }, marginRight: '10px' }}
                    />
                  </IconButton>
                )
              ) : (
                <IconButton edge="start" color="inherit" component={Link} to="/">
                  <Box
                    component="img"
                    src={logo}
                    alt="Logo"
                    sx={{ height: { xs: '50px', md: '80px' }, marginRight: '10px' }}
                  />
                </IconButton>
              )}
              <Box sx={{ flexGrow: 1 }} />
              {currentUser ? (
                <>
                  <Tooltip title="Give Feedback">
                    <IconButton
                      color="inherit"
                      onClick={() => navigate('/help')}
                      sx={{ fontSize: '32px', '& svg': { fontSize: '32px' } }}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    color="inherit"
                    onClick={(e) => handleMenuClick(e, 'account')}
                    sx={{ fontSize: '32px', '& svg': { fontSize: '32px' } }}
                  >
                    <AccountCircleIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Button
                    component={Link}
                    to="/signup"
                    color="inherit"
                    sx={{
                      fontSize: '18px',
                      display: { xs: 'block', sm: 'block' },
                    }}
                  >
                    Sign Up
                  </Button>
                  <Button
                    component={Link}
                    to="/signin"
                    color="inherit"
                    sx={{
                      fontSize: '18px',
                      display: { xs: 'block', sm: 'block' },
                    }}
                  >
                    Sign In
                  </Button>
                </>
              )}
            </Toolbar>
          </AppBar>
        )}

        {menuType === 'account' && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {/* We now prefetch portal URL on hover and via useEffect */}
            <MenuItem
              onMouseEnter={sendToCustomerPortalOnHover}
              onClick={sendToCustomerPortal}
            >
              Manage Account
            </MenuItem>
            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
          </Menu>
        )}

        <Box
          sx={{
            width: '100%',
            paddingTop: { xs: '64px', md: '80px' },
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/eventdashboard" element={<EventDashboard />} />
            <Route path="/create-event" element={<PrivateRoute element={EventCreationForm} />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/subpage" element={<Subpage />} />
            <Route path="/events/:uniqueUrl" element={<EventPage />} />
            <Route path="/qrcode-templates" element={<QRCodeTemplates />} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivatePolicy />} />
            <Route path="/help" element={<HelpSupport />} />
            <Route
              path="/edit-event/:eventId/step1"
              element={<PrivateRoute element={(props) => <EventCreationForm {...props} editMode={true} />} />}
            />
            <Route
              path="/edit-event/:eventId/step2"
              element={<PrivateRoute element={(props) => <EventCustomizationForm {...props} editMode={true} />} />}
            />
            <Route
              path="/edit-event/:eventId/step3"
              element={<PrivateRoute element={(props) => <EventPreviewForm {...props} editMode={true} />} />}
            />
            {/* New route for handling email action links */}
            <Route path="/__auth/action" element={<EmailActionHandler />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default App;
