import { collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase/firebaseConfig';

// Define event limits based on the new plan price IDs – now only 1 event is allowed per user regardless of plan
const eventLimits = {
  'price_1R85uFRtiroMBcDI97u58KNs': 1,  // Event Flash
  'price_1QftFXRtiroMBcDIFhy8TSYQ': 1,  // Event Glow-Up
  'price_1R85VSRtiroMBcDIXv9u0P6q': 1,  // Social Spark
  'price_1R85YsRtiroMBcDIi5iHkGUb': 1,  // VIP Experience
  'price_1R85wBRtiroMBcDIf4HpIbr7': 1   // Premium
};

// List of plan price IDs for subscription checking
const planPriceIds = [
  'price_1R85uFRtiroMBcDI97u58KNs', // Event Flash
  'price_1QftFXRtiroMBcDIFhy8TSYQ',  // Event Glow-Up
  'price_1R85VSRtiroMBcDIXv9u0P6q',  // Social Spark
  'price_1R85YsRtiroMBcDIi5iHkGUb',  // VIP Experience
  'price_1R85wBRtiroMBcDIf4HpIbr7'   // Premium
];

// Function to fetch the checkout session for the user based on a given priceId
const getCheckoutSessionByPrice = async (priceId) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      console.error('User is not authenticated.');
      return null;
    }
    const userId = user.uid;
    const sessionsRef = collection(db, `users/${userId}/checkout_sessions`);
    const q = query(sessionsRef, where('price', '==', priceId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs[0]?.data() || null;
  } catch (error) {
    console.error('Error getting documents: ', error.message);
    return null;
  }
};

// Function to check if the user can create more events (now limited to 1 event)
export const canUserCreateEvent = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    alert('User is not authenticated.');
    return false;
  }
  const userId = user.uid;
  const userRef = doc(db, 'users', userId);
  try {
    let checkoutSession = null;
    for (let priceId of planPriceIds) {
      checkoutSession = await getCheckoutSessionByPrice(priceId);
      if (checkoutSession) break;
    }
    if (!checkoutSession) {
      alert('No active subscription or payment found.');
      return false;
    }
    const priceId = checkoutSession.price; // Get the priceId from the session
    const eventLimit = eventLimits[priceId] || 0;
    const userDoc = await getDoc(userRef);
    const currentEventCount = userDoc.exists() ? userDoc.data().eventCount || 0 : 0;
    if (currentEventCount >= eventLimit) {
      alert('Event creation limit reached for your plan.');
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error checking event limit:', error.message);
    return false;
  }
};

// Function to handle event creation and update the user's event count
export const createEvent = async (eventData) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    console.error('User not authenticated');
    return;
  }
  const userId = user.uid;
  const userRef = doc(db, 'users', userId);
  try {
    const userDoc = await getDoc(userRef);
    const currentEventCount = userDoc.exists() ? userDoc.data().eventCount || 0 : 0;
    const eventRef = doc(db, 'events', eventData.uniqueUrl);
    await setDoc(eventRef, eventData);
    await updateDoc(userRef, { eventCount: currentEventCount + 1 });
    console.log('Event created successfully');
  } catch (error) {
    console.error('Error creating event:', error.message);
    alert('Failed to create the event. Please try again.');
  }
};
