// FAQ.jsx
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  return (
    <Container maxWidth="md" sx={{ paddingY: 6, backgroundColor: '#f9f9f9', borderRadius: 3, boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}>
      {/* Page Title */}
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 4, color: '#333' }}>
        Frequently Asked Questions
      </Typography>

      {/* Section: General Information */}
      <Box sx={{ marginBottom: 5 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium', color: '#555' }}>
          General Information
        </Typography>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>What is Elevate Event Studio?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Elevate Event Studio is a versatile platform that lets you create live galleries effortlessly. Instantly generate a QR code so your guests can upload photos and videos directly to a centralized digital gallery.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>When is the QR code generated?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              The QR code is generated instantly when you create your live gallery, making it easy to share immediately with your guests.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Section: Gallery Customization */}
      <Box sx={{ marginBottom: 5 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium', color: '#555' }}>
          Gallery Customization
        </Typography>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>What can I customize in my live gallery?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Customize your gallery title, add a cover photo, and include a personalized welcome message to create a unique and engaging experience.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Can I use the QR code on promotional materials?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Yes, download the QR code and add it to posters, invitations, or any other promotional materials to easily invite guests to your live gallery.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Can I share the QR code via email?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Absolutely. Sharing the QR code via email is a quick and convenient way to invite your contacts to join your live gallery.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Section: Media Collection and Sharing */}
      <Box>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium', color: '#555' }}>
          Media Collection and Sharing
        </Typography>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Why not use Google Drive or Dropbox for photo collection?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Although Google Drive and Dropbox are excellent for file storage, they don't provide the same level of control over uploads and permissions. Elevate Event Studio offers a dedicated, streamlined live gallery experience.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Why is email not ideal for photo collection?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Collecting photos via email can be cumbersome and disorganized. Our platform streamlines the process by offering a centralized, easy-to-manage live gallery.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ border: 'none', borderRadius: 3, boxShadow: '0 2px 15px rgba(0,0,0,0.1)', marginBottom: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#ffffff', borderRadius: 3 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#444' }}>Can users download and share photos?</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: 3 }}>
            <Typography color="textSecondary">
              Yes, users can easily download and share their favorite photos directly from the live gallery, ensuring memories are always at your fingertips.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
};

export default FAQ;
