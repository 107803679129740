import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from '@mui/material';
import { Home, QrCode, Description } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import logo from '../assets/logo.svg';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Sidebar = ({ mobileOpen: propMobileOpen, setMobileOpen: propSetMobileOpen, isAuthenticated }) => {
  const [internalMobileOpen, setInternalMobileOpen] = useState(false);
  const mobileOpen = propMobileOpen !== undefined ? propMobileOpen : internalMobileOpen;
  const setMobileOpen = propSetMobileOpen !== undefined ? propSetMobileOpen : setInternalMobileOpen;

  const [hasSubscriptions, setHasSubscriptions] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    let unsubscribeSubscriptions;
    let unsubscribePayments;

    const fetchData = async (user) => {
      const subscriptionsRef = collection(db, `users/${user.uid}/subscriptions`);
      const paymentsRef = collection(db, `users/${user.uid}/payments`);

      unsubscribeSubscriptions = onSnapshot(subscriptionsRef, (snapshot) => {
        setHasSubscriptions(!snapshot.empty);
      });

      unsubscribePayments = onSnapshot(paymentsRef, (snapshot) => {
        setHasSubscriptions((prev) => prev || !snapshot.empty);
      });
    };

    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchData(user);
      } else {
        setHasSubscriptions(false);
      }
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeSubscriptions) unsubscribeSubscriptions();
      if (unsubscribePayments) unsubscribePayments();
    };
  }, [location.pathname]);

  const hiddenPaths = ['/subpage', '/', '/signin', '/signup'];
  if (hiddenPaths.includes(location.pathname) || !hasSubscriptions) {
    return null;
  }

  const drawerContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: 'linear-gradient(to bottom, #E6E7E8, #D6D7D8)',
        color: '#6c757d',
      }}
    >
      {/* Logo */}
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Box
          component="img"
          src={logo}
          alt="Logo"
          sx={{
            width: '50px',
            height: '50px',
            mb: 1,
          }}
        />
      </Box>

      {/* Navigation Items */}
      <List sx={{ py: 1 }}>
        {/* HOME */}
        <ListItem
          button
          component={Link}
          to="/eventdashboard"
          sx={{
            '&:hover': {
              backgroundColor: '#E0E0E0',
            },
          }}
        >
          <ListItemIcon>
            <Home
              sx={{
                fontSize: '24px',
                color: location.pathname === '/eventdashboard' ? '#007bff' : '#5a5a5a',
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>

        {/* POSTER TEMPLATES */}
        <ListItem
          button
          component={Link}
          to="/qrcode-templates"
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(0, 123, 255, 0.1)',
            },
          }}
        >
          <ListItemIcon>
            <QrCode
              sx={{
                fontSize: '24px',
                color: location.pathname === '/qrcode-templates' ? '#007bff' : '#5a5a5a',
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Poster Templates" />
        </ListItem>

        {/* DOCUMENTS */}
        <ListItem
          button
          component={Link}
          to="/docs"
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(0, 123, 255, 0.1)',
            },
          }}
        >
          <ListItemIcon>
            <Description
              sx={{
                fontSize: '24px',
                color: location.pathname === '/docs' ? '#007bff' : '#5a5a5a',
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Documents" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          ModalProps={{ keepMounted: true }}
          sx={{
            '& .MuiDrawer-paper': {
              width: 240,
              boxSizing: 'border-box',
            },
          }}
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            '& .MuiDrawer-paper': {
              width: 240,
              boxSizing: 'border-box',
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
