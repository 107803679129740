import React from 'react';
import { Container, Paper, Typography, Box } from '@mui/material';

const HelpSupport = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: { xs: 3, md: 5 },
        mb: { xs: 3, md: 5 },
        px: { xs: 2, sm: 4, md: 6 },
      }}
    >
      <Paper
        elevation={4}
        sx={{
          p: { xs: 3, sm: 4, md: 6 },
          textAlign: 'center',
          borderRadius: 4,
          backgroundColor: '#ffffff',
          boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.1),
                      0px 8px 16px rgba(0, 0, 0, 0.1)`,
        }}
      >
        {/* Title */}
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontWeight: 'bold',
            color: '#333',
            mb: 1,
            fontSize: { xs: '1.8rem', md: '2.5rem' },
          }}
        >
          Help & Feedback
        </Typography>

        {/* Subtitle */}
        <Typography
          variant="body1"
          sx={{
            mb: 3,
            fontSize: { xs: '1rem', md: '1.2rem' },
            color: 'text.secondary',
          }}
        >
          Submit your issues or feedback below:
        </Typography>

        {/* Google Form Embed with Internal Scroll */}
        <Box
          sx={{
            width: '100%',
            height: { xs: '600px', md: '800px' }, // Fixed height for better visibility
            overflow: 'hidden', // Prevents unnecessary outer scrolling
            borderRadius: 2,
            border: '1px solid #ddd',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfElxt5U3dIoYe2VNJoKHTFqH40KNEwZk5yk6JF5_G37xjyWg/viewform?embedded=true"
            width="100%"
            height="100%"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="Feedback Form"
            style={{
              borderRadius: '8px',
              border: 'none',
              minHeight: '100%',
              overflow: 'auto', // Enables scrolling inside iframe, keeping page fixed
            }}
          >
            Loading…
          </iframe>
        </Box>
      </Paper>
    </Container>
  );
};

export default HelpSupport;
