import React from 'react';
import { Typography, Box, Card, Button } from '@mui/material';

const templates = [
  {
    title: 'Elegant Engagement Party Table Sign',
    embed: 'https://www.canva.com/design/DAGYXWuObk8/xNSiLowJtnUd8mdiFLIdOw/view?embed',
    link: 'https://www.canva.com/design/DAGYXWuObk8/xNSiLowJtnUd8mdiFLIdOw/view?utm_content=DAGYXWuObk8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview',
    thumbnail: 'https://firebasestorage.googleapis.com/v0/b/scan-cap.appspot.com/o/template-thumbnails%2FEngagement%20Party.jpg?alt=media&token=5c23d64c-1a6b-4b2a-9303-d90f0ff30c24',
  },
  {
    title: 'Aesthetic Quinceañera Table Sign',
    embed: 'https://www.canva.com/design/DAGYXgL2pso/8xw5C7AwJr2YI2LERF0r4Q/view?embed',
    link: 'https://www.canva.com/design/DAGYXgL2pso/8xw5C7AwJr2YI2LERF0r4Q/view?utm_content=DAGYXgL2pso&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview',
    thumbnail: 'https://firebasestorage.googleapis.com/v0/b/scan-cap.appspot.com/o/template-thumbnails%2FQuinceanera.jpg?alt=media&token=5763e766-9f78-41a4-8bc4-0a886bd0bb2d',
  },
  {
    title: 'Modern Wedding QR Code Poster',
    embed: 'https://www.canva.com/design/DAGcaAh_T34/m7VgBVCNhIvxQMP0y_eGzg/view?embed',
    link: 'https://www.canva.com/design/DAGcaAh_T34/m7VgBVCNhIvxQMP0y_eGzg/view?utm_content=DAGcaAh_T34&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview',
    thumbnail: 'https://firebasestorage.googleapis.com/v0/b/scan-cap.appspot.com/o/template-thumbnails%2FWedding%20Template.jpg?alt=media&token=ac491808-cf28-4660-a206-8bc055fb021b',
  },
];

const QRCodeTemplates = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: { xs: '10px', sm: '20px' },
        backgroundColor: '#f9f9f9',
      }}
    >
      {/* Page Title */}
      <Typography
        variant="h3"
        component="h1"
        sx={{
          fontWeight: 'bold',
          marginBottom: '20px',
          color: '#333',
          fontSize: { xs: '1.8rem', sm: '2.5rem' }, // Responsive font size
          textAlign: 'center',
        }}
      >
        Canva Templates
      </Typography>

      {/* Subtitle */}
      <Typography
        variant="h6"
        component="h2"
        color="textSecondary"
        sx={{
          marginBottom: '30px',
          textAlign: 'center',
          fontSize: { xs: '1rem', sm: '1.2rem' },
          padding: { xs: '0 10px', sm: '0' }, // Add some padding on mobile
        }}
      >
        Click any template below to explore or customize it for your events.
      </Typography>

      {/* Templates Grid */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '20px',
          width: '100%',
        }}
      >
        {templates.map((template, index) => (
          <Card
            key={index}
            sx={{
              width: { xs: '90%', sm: '250px', md: '300px' }, // Responsive width
              borderRadius: '10px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
              backgroundColor: '#fff',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
                cursor: 'pointer',
              },
            }}
          >
            <a
              href={template.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Box sx={{ position: 'relative' }}>
                {/* Template Image */}
                <img
                  src={template.thumbnail}
                  alt={template.title}
                  loading="lazy"
                  style={{
                    width: '100%',
                    height: 'auto', // Keep aspect ratio for responsiveness
                    maxHeight: { xs: '200px', sm: '250px', md: '300px' }, // Adjust heights
                    objectFit: 'cover',
                  }}
                />
                {/* Hover Overlay Effect */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.4)',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: 0,
                    transition: 'opacity 0.3s ease',
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                >
                  <Typography variant="h6">Click to View</Typography>
                </Box>
              </Box>

              {/* Template Title & Button */}
              <Box sx={{ padding: '16px', textAlign: 'center' }}>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontWeight: 'bold', color: '#333', fontSize: { xs: '1rem', sm: '1.2rem' } }}
                >
                  {template.title}
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    marginTop: '8px',
                    background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)',
                    color: '#fff',
                    textTransform: 'none',
                    '&:hover': {
                      background: 'linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)',
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(template.link, '_blank');
                  }}
                >
                  View Template
                </Button>
              </Box>
            </a>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default QRCodeTemplates;
