import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Ensure updateDoc is included
import { db } from '../firebase/firebaseConfig';
import {
  Button,
  Container,
  Typography,
  Box,
  Paper,
  InputBase,
} from '@mui/material';
import { CalendarToday, ArrowForward } from '@mui/icons-material';

const EventDetailsForm = ({ onNext, editMode = false }) => {
  const { eventId } = useParams(); // Get event ID from URL
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');

  useEffect(() => {
    if (editMode && eventId) {
      const fetchEventDetails = async () => {
        try {
          const docRef = doc(db, 'events', eventId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setEventName(data.eventName || '');
            setEventDate(data.eventDate || '');
            setWelcomeMessage(data.welcomeMessage || '');
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching event data:', error);
        }
      };
      fetchEventDetails();
    }
  }, [editMode, eventId]);

  const handleNext = async () => {
    if (!eventName || !eventDate) {
      alert('Please provide event name and date.');
      return;
    }

    if (editMode && eventId) {
      try {
        const eventRef = doc(db, 'events', eventId);
        await updateDoc(eventRef, {
          'details.eventName': eventName,
          'details.eventDate': eventDate,
          'details.welcomeMessage': welcomeMessage,
        });
        console.log('✅ Event details updated successfully!');
      } catch (error) {
        console.error('❌ Error updating event details:', error);
      }
    }

    onNext({ eventName, eventDate, welcomeMessage });
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 4,
        mb: 4,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={8}
        sx={{
          p: 5,
          borderRadius: 4,
          maxWidth: 500,
          width: '100%',
          textAlign: 'center',
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
          backgroundColor: '#fff',
        }}
      >
        <Box mb={2} sx={{ animation: 'bounce 2s infinite' }}>
          <CalendarToday
            sx={{
              fontSize: 64,
              color: '#4A90E2',
              '@keyframes bounce': {
                '0%, 100%': { transform: 'translateY(0)' },
                '50%': { transform: 'translateY(-5px)' },
              },
            }}
          />
        </Box>

        {/* Removed the redundant heading here. */}
        <Typography variant="body2" sx={{ mb: 3, color: '#666' }}>
          {editMode ? 'Update your event details.' : 'Enter your event details to get started.'}
        </Typography>

        <Box mb={3} textAlign="left">
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Event Name *</Typography>
          <InputBase
            placeholder="Enter Event Name"
            fullWidth
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            sx={{
              border: '1px solid #DDD',
              borderRadius: 3,
              px: 2,
              py: 1,
              '&:hover': { borderColor: '#4A90E2' },
              '&:focus-within': { borderColor: '#1E6FB6' },
            }}
          />
        </Box>

        <Box mb={3} textAlign="left">
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Event Date *</Typography>
          <InputBase
            type="date"
            fullWidth
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
            sx={{
              border: '1px solid #DDD',
              borderRadius: 3,
              px: 2,
              py: 1,
              '&:hover': { borderColor: '#4A90E2' },
              '&:focus-within': { borderColor: '#1E6FB6' },
            }}
          />
        </Box>

        <Box mb={4} textAlign="left">
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Custom Greeting</Typography>
          <InputBase
            placeholder="Create a warm message to welcome your guests."
            fullWidth
            multiline
            rows={3}
            value={welcomeMessage}
            onChange={(e) => setWelcomeMessage(e.target.value)}
            sx={{
              border: '1px solid #DDD',
              borderRadius: 3,
              px: 2,
              py: 1,
              '&:hover': { borderColor: '#4A90E2' },
              '&:focus-within': { borderColor: '#1E6FB6' },
            }}
          />
        </Box>

        <Box textAlign="right">
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{
              background: 'linear-gradient(90deg, #4A90E2, #1E6FB6)',
              color: '#fff',
              padding: '10px 20px',
              fontWeight: 'bold',
              borderRadius: '30px',
              textTransform: 'none',
              fontSize: '1rem',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
                background: 'linear-gradient(90deg, #1E6FB6, #145A8C)',
                boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.2)',
              },
            }}
            endIcon={<ArrowForward />}
          >
            Next
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default EventDetailsForm;
