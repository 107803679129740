import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  CardMedia,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {db} from '../firebase/firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot
} from 'firebase/firestore';
import dayjs from 'dayjs';
import { getAuth, onAuthStateChanged} from 'firebase/auth';
import Sidebar from './Sidebar';
import CircularProgress from '@mui/material/CircularProgress';
import { AddCircleOutline, Delete, MoreVert, Edit, QrCode } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Visibility } from '@mui/icons-material';







const EventDashboard = () => {
  const [events, setEvents] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false); // For opening/closing dialog
const [eventToDelete, setEventToDelete] = useState(null); // To track the event being deleted
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const [anchorEl, setAnchorEl] = useState(null);
const [selectedEvent, setSelectedEvent] = useState(null);

const handleMenuOpen = (event, selectedEvent) => {
  setAnchorEl(event.currentTarget);
  setSelectedEvent(selectedEvent);
};

const handleMenuClose = () => {
  setAnchorEl(null);
  setSelectedEvent(null);
};
const handleDeleteClick = (event) => {
  setEventToDelete(event); // Set the event to delete
  setDialogOpen(true); // Open the dialog
};

const handleConfirmDelete = async () => {
  if (!eventToDelete) return; // Ensure eventToDelete is not null
  
  try {
    await deleteDoc(doc(db, 'events', eventToDelete.id));
    setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventToDelete.id));
    console.log('Event deleted successfully');
  } catch (error) {
    console.error('Error deleting event:', error);
  }
  
  setDialogOpen(false);
  setEventToDelete(null);
};


const handleCancelDelete = () => {
  setDialogOpen(false); // Close the dialog
  setEventToDelete(null); // Clear selected event
};
const handleEditDetails = () => {
  navigate(`/edit-event/${selectedEvent.id}/step1`);
  handleMenuClose();
};

const handleEditCustomization = () => {
  navigate(`/edit-event/${selectedEvent.id}/step2`);
  handleMenuClose();
};

const handleRetrieveQRCode = () => {
  navigate(`/edit-event/${selectedEvent.id}/step3`);
  handleMenuClose();
};


useEffect(() => {
  const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      setUser(currentUser);
      const unsubscribeEvents = fetchEvents(currentUser.uid); // Subscribe to real-time updates
      return () => unsubscribeEvents(); // Cleanup event listener when user changes or component unmounts
    } else {
      setLoading(false);
    }
  });

  return () => unsubscribeAuth(); // Cleanup auth listener
}, [auth]);


const fetchEvents = (userId) => {
  const eventsRef = collection(db, "events");
  const q = query(eventsRef, where("userId", "==", userId));

  // Real-time listener
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const eventsList = querySnapshot.docs.map((doc) => {
      const data = doc.data();

      return {
        id: doc.id,
        ...data,
        eventName: data.details?.eventName || "Untitled Event", // Safely handle nested fields
        eventDate: data.details?.eventDate || null, // Default to null if missing
        welcomeMessage: data.details?.welcomeMessage || "",
        coverPhotoUrl: data.customization?.coverPhotoUrl || "", // Handle nested customization
      };
    });

    eventsList.sort((a, b) => {
      const dateA = new Date(a.eventDate || 0);
      const dateB = new Date(b.eventDate || 0);
      return dateA - dateB;
    });

    setEvents(eventsList);
    setLoading(false);
  });

  return unsubscribe; // Return the unsubscribe function
};

  

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'events', id));
      setEvents(events.filter((event) => event.id !== id));
      console.log('Event deleted successfully');
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const upcomingEvents = events.filter((event) => dayjs(event.eventDate).isAfter(dayjs()));
  const completedEvents = events.filter((event) => dayjs(event.eventDate).isBefore(dayjs()));

  if (loading) {
   return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f0f4f8',
        }}
      >
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  }


  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Sidebar />
      <Container
        sx={{
          padding: '20px',
          background: 'linear-gradient(to bottom, #f0f4f8, #ffffff)',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          marginTop: '20px',
          marginBottom: '20px',
          width: 'calc(100% - 240px)',
          flexGrow: 1,
        }}
      >
        {upcomingEvents.length === 0 && completedEvents.length === 0 && (
          <Box sx={{ textAlign: 'center', marginBottom: '40px' }}>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
          >
            Welcome to Your{' '}
            <Box
              component="span"
              sx={{
                color: '#2A73E8', // Blue color for "Event Dashboard"
              }}
            >
              Event Dashboard
            </Box>
            !
          </Typography>
          <Typography
  variant="h6"
  sx={{
    marginBottom: '30px',
  }}
>
  <span style={{ color: '#000000' }}>Collect photos from every angle and share memories. Get started by</span>{' '}
  <span
  style={{
    color: '#2A73E8', // Blue text
    fontWeight: 'bold',
  }}
>
  creating your first event.
</span>

</Typography>


          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            component={Link}
            to="/create-event"
            sx={{
              background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)', // Blue gradient
              color: '#FFFFFF',
              fontWeight: 'bold',
              padding: '12px 24px',
              borderRadius: '8px',
              textTransform: 'none',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              '&:hover': {
                background: 'linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)', // Slightly darker hover gradient
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', // Enhanced hover shadow
              },
            }}
          >
            Create New Event
          </Button>
        </Box>
        
        )}

        {(upcomingEvents.length > 0 || completedEvents.length > 0) && (
          <>
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', marginBottom: '30px' }}>
              Event Dashboard
            </Typography>
            <Button
  variant="contained"
  startIcon={<AddCircleOutline />}
  component={Link}
  to="/create-event"
  sx={{
    background: 'linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)', // Premium gradient
    color: '#FFFFFF',
    fontWeight: 'bold',
    padding: '12px 24px',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
    '&:hover': {
      background: 'linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)', // Slightly darker gradient on hover
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', // Enhanced shadow on hover
    },
  }}
>
  Create New Event
</Button>

          </>
        )}

        <Typography variant="h5" component="h2" sx={{ fontWeight: '600', marginBottom: '10px', textAlign: 'center' }}>
          Upcoming Events
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            overflowX: 'auto',
            padding: '10px 0',
            scrollBehavior: 'smooth',
          }}
        >
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map((event) => (
              <Card
  key={event.id}
  sx={{
    width: '350px',
    height: '500px',
    flexShrink: 0,
    borderRadius: '16px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)' },
    position: 'relative', // Allows absolute positioning inside
  }}
>
  {/* Three-Dot Menu Icon - Placed in the Top Right Corner */}
  <IconButton
  onClick={(e) => handleMenuOpen(e, event)}
  sx={{
    position: 'absolute',
    top: 8,
    right: 8,
    color: '#000', // Black dots
    backgroundColor: '#fff', // White circular background
    borderRadius: '50%', // Makes it circular
    padding: '8px', // Ensures a large clickable area
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
    zIndex: 2, // Ensures it's above content
    '&:hover': {
      backgroundColor: '#f0f0f0', // Light gray on hover
    },
    '& svg': {
      fontSize: '24px', // Bigger icon for visibility
    },
  }}
>
  <MoreVert />
</IconButton>



  {/* Card Media */}
  <CardMedia
    component="img"
    height="240"
    image={event.coverPhotoUrl}
    alt={event.eventName}
    sx={{
      borderRadius: '16px 16px 0 0',
      objectFit: 'cover',
      objectPosition: '50% 30%',
    }}
  />

  {/* Card Content */}
  <CardContent sx={{ padding: '24px' }}>
  <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
    {event.details?.eventName || "Untitled Event"} {/* Safe navigation for nested field */}
  </Typography>
  <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '8px' }}>
    {dayjs(event.details?.eventDate).format('MMMM D, YYYY')}
  </Typography>
</CardContent>


  {/* Card Actions - "View Details" button remains at the bottom */}
  <CardActions
    sx={{
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '16px',
    }}
  >
    <Button
  size="small"
  onClick={() => navigate(`/events/${event.uniqueUrl}`)}
  startIcon={<Visibility />}
  sx={{
    textTransform: 'none',
    fontSize: '14px',
    color: '#1e88e5',
    fontWeight: 'bold',
  }}
>
  View your LIVE Gallery
</Button>

  </CardActions>

  {/* Menu Options */}
  <Menu
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}
  onClose={handleMenuClose}
  PaperProps={{
    elevation: 3,
    style: { minWidth: '200px' },
  }}
>
  {/* Only keep Edit Event Details and Delete Event */}
  <MenuItem onClick={handleEditDetails}>
    <Edit fontSize="small" sx={{ marginRight: '8px' }} />
    Edit Event Details
  </MenuItem>

  <MenuItem onClick={() => handleDeleteClick(selectedEvent)}>
    <Delete fontSize="small" sx={{ marginRight: '8px', color: '#d32f2f' }} />
    Delete Event
  </MenuItem>
</Menu>

</Card>

            ))
          ) : (
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
                textAlign: 'center',
              }}
            >
             Your events will appear here.
            </Typography>
          )}
        </Box>

        {completedEvents.length > 0 && (
          <>
            <Typography variant="h5" component="h2" sx={{ fontWeight: '600', marginTop: '40px', marginBottom: '10px' }}>
              Completed Events
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                overflowX: 'auto',
                padding: '10px 0',
                scrollBehavior: 'smooth',
              }}
            >
              {completedEvents.map((event) => (
                <Card
                  key={event.id}
                  sx={{
                    width: '350px',
                    height: '500px',
                    flexShrink: 0,
                    borderRadius: '16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': { transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)' },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="240"
                    image={event.coverPhotoUrl}
                    alt={event.eventName}
                    sx={{
                      borderRadius: '16px 16px 0 0',
                      objectFit: 'cover',
                      objectPosition: '50% 30%',
                    }}
                  />
                  <CardContent sx={{ padding: '24px' }}>
                    <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
                      {event.eventName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '8px' }}>
                      {dayjs(event.eventDate).format('MMMM D, YYYY')}
                    </Typography>
                  </CardContent>
                
                  <CardActions
  sx={{
    display: 'flex',
    justifyContent: 'space-between', // Ensures space between elements
    alignItems: 'center',            // Aligns items vertically in the center
    padding: '16px 24px',            // Adds padding for consistent spacing
  }}
>
  {/* View Details Button */}
  <Button
  size="small"
  onClick={() => navigate(`/events/${event.uniqueUrl}`)}
  startIcon={<Visibility />}
  sx={{
    textTransform: 'none',
    fontSize: '14px',
    color: '#1e88e5',
    fontWeight: 'bold',
  }}
>
  View your LIVE Gallery
</Button>

  {/* Three-Dot Menu Trigger */}
  <IconButton
    onClick={(e) => handleMenuOpen(e, event)}
    sx={{
      color: 'rgba(0, 0, 0, 0.54)', // Standard MUI icon color
    }}
  >
    <MoreVert />
  </IconButton>

  {/* Menu Options */}
  <Menu
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}
  onClose={handleMenuClose}
  PaperProps={{
    elevation: 3,
    style: { minWidth: '200px' },
  }}
>
  {/* Edit Event Details */}
  <MenuItem onClick={handleEditDetails}>
    <Edit fontSize="small" sx={{ marginRight: '8px' }} />
    Edit Event Details
  </MenuItem>

  {/* Delete Event */}
  <MenuItem onClick={() => handleDeleteClick(selectedEvent)}>
    <Delete fontSize="small" sx={{ marginRight: '8px', color: '#d32f2f' }} />
    Delete Event
  </MenuItem>
</Menu>

</CardActions>


                </Card>
              ))}
            </Box>
          </>
        )}
      </Container>
      <Dialog
  open={dialogOpen}
  onClose={handleCancelDelete}
  aria-labelledby="confirm-delete-title"
  aria-describedby="confirm-delete-description"
>
  <DialogTitle id="confirm-delete-title">Are you sure?</DialogTitle>
  <DialogContent>
    <Typography id="confirm-delete-description">
      Deleting this event will remove it permanently, and this action cannot be undone.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCancelDelete} color="primary">
      No, Cancel
    </Button>
    <Button onClick={handleConfirmDelete} color="error">
      Yes, Delete
    </Button>
  </DialogActions>
</Dialog>

    </div>
  );
};

export default EventDashboard;
