// MobileCustomization.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, SwipeableDrawer, Tooltip } from '@mui/material';
import { keyframes } from '@mui/system';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EventCustomizationForm from './EventCustomizationForm';

// Define bounce animation
const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-10px); }
  60% { transform: translateY(-5px); }
`;

const MobileCustomization = ({
  eventDetails,           // e.g. { eventName, eventDate, welcomeMessage }
  customizationData,      // object with customization properties
  setCustomizationData,   // state setter to update customizationData
  setStep,                // function to move to the next step
  editMode,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scaleValue, setScaleValue] = useState(0.8);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipTimerRef = useRef(null);

  // Bouncing arrow state and timer
  const [showArrow, setShowArrow] = useState(false);
  const arrowTimerRef = useRef(null);

  // Reset inactivity timer to show bouncing arrow after 2.5s
  const resetArrowTimer = () => {
    setShowArrow(false);
    if (arrowTimerRef.current) clearTimeout(arrowTimerRef.current);
    arrowTimerRef.current = setTimeout(() => setShowArrow(true), 2500);
  };

  useEffect(() => {
    // Listen for user activity to reset arrow timer
    const events = ['mousemove', 'scroll', 'touchstart', 'click'];
    events.forEach((eventName) => window.addEventListener(eventName, resetArrowTimer));
    resetArrowTimer();
    return () => {
      events.forEach((eventName) => window.removeEventListener(eventName, resetArrowTimer));
      if (arrowTimerRef.current) clearTimeout(arrowTimerRef.current);
    };
  }, []);

  useEffect(() => {
    // Scroll window to top on mount
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Adjust phone preview scale on window resize
    const updateScale = () => {
      setScaleValue(window.innerWidth <= 768 ? 0.8 : 1);
    };
    updateScale();
    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  }, []);

  // Close tooltip if user clicks elsewhere
  useEffect(() => {
    const handleClickOutside = () => {
      if (tooltipOpen) setTooltipOpen(false);
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [tooltipOpen]);

  // Clear tooltip timer on unmount
  useEffect(() => {
    return () => {
      if (tooltipTimerRef.current) clearTimeout(tooltipTimerRef.current);
    };
  }, []);

  // Default coverPhotoZoom if not provided
  const coverPhotoZoom =
    customizationData.coverPhotoZoom !== undefined
      ? customizationData.coverPhotoZoom
      : 1;

  const handleUploadClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setTooltipOpen(true);
    if (tooltipTimerRef.current) {
      clearTimeout(tooltipTimerRef.current);
    }
    tooltipTimerRef.current = setTimeout(() => {
      setTooltipOpen(false);
    }, 3000);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        background: '#f0f0f0',
        position: 'relative',
        overflowY: 'auto',
        p: 2,
      }}
    >
      {/* Outer Phone Preview – shows real-time updates */}
      <Box
        sx={{
          width: '300px',
          height: '600px',
          backgroundColor: customizationData.backgroundColor,
          borderRadius: '40px',
          boxShadow: '0 0 20px rgba(0,0,0,0.2)',
          margin: '0 auto', // no bottom margin yet
          mb: '60px',       // extra space so the handle overlaps slightly
          transform: `scale(${scaleValue})`,
          transformOrigin: 'top center',
        }}
      >
        <Box sx={{ pt: 4, textAlign: 'center' }}>
          {/* Cover Photo Container */}
          <Box
            sx={{
              width: 120,
              height: 120,
              borderRadius: '50%',
              overflow: 'hidden',
              border: '4px solid #ccc',
              margin: '0 auto 10px',
              position: 'relative',
              cursor: 'pointer',
            }}
            onClick={() => setDrawerOpen(true)}
          >
            <img
              src={customizationData.coverPhotoUrl}
              alt="Cover"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transform: `scale(${coverPhotoZoom})`,
              }}
            />
            {(!customizationData.coverPhotoUrl ||
              customizationData.coverPhotoUrl.includes('placeholder')) && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '12px',
                  textAlign: 'center',
                  p: 1,
                }}
              >
                Tap to upload<br />cover photo
              </Box>
            )}
          </Box>

          {/* Cover Photo Label */}
          <Box sx={{ fontSize: '12px', color: '#888', mb: 2 }}>
            Event Cover Photo (Visible to all guests)
          </Box>

          {/* Event Title */}
          {customizationData.showTitle !== false && (
            <Box
              sx={{
                color: customizationData.titleFontColor,
                fontFamily: customizationData.titleFont,
                fontSize: customizationData.titleFontSize,
                fontWeight: customizationData.titleBold ? 'bold' : 'normal',
              }}
            >
              {eventDetails?.eventName || 'Untitled Event'}
            </Box>
          )}

          {/* Event Date */}
          {customizationData.showDate !== false && eventDetails.eventDate && (
            <Box
              sx={{
                mt: 1,
                fontFamily: customizationData.dateFont,
                fontSize: customizationData.dateFontSize,
                color: customizationData.dateFontColor,
                fontWeight: customizationData.dateBold ? 'bold' : 'normal',
              }}
            >
              {eventDetails.eventDate}
            </Box>
          )}

          {/* Welcome Message */}
          {customizationData.showWelcomeMessage !== false && eventDetails.welcomeMessage && (
            <Box
              sx={{
                mt: 1,
                px: 2,
                fontFamily: customizationData.welcomeFont,
                fontSize: customizationData.welcomeFontSize,
                color: customizationData.welcomeFontColor,
                fontWeight: customizationData.welcomeBold ? 'bold' : 'normal',
              }}
            >
              {eventDetails.welcomeMessage}
            </Box>
          )}

          {/* Upload Button with Tooltip */}
          <Box sx={{ mt: 2 }}>
            <Tooltip
              title="Guests will use this button to upload media during the event"
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'rgba(30, 58, 95, 0.8)',
                    boxShadow: '0px 4px 8px rgba(0,0,0,0.3)',
                    borderRadius: '6px',
                  },
                },
              }}
            >
              <Button
                disableElevation
                variant="contained"
                sx={{
                  backgroundColor: customizationData.buttonColor,
                  boxShadow: customizationData.buttonGlow,
                  transition: 'none',
                }}
                onClick={handleUploadClick}
              >
                Upload Photos/Videos
              </Button>
            </Tooltip>
          </Box>

          {/* Neutral Placeholder for Photo Gallery */}
          <Box
            sx={{
              marginTop: '20px',
              width: '80%',
              height: '250px',
              mx: 'auto',
              borderRadius: '8px',
              background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#666',
            }}
          >
            Your photos will appear here
          </Box>
        </Box>
      </Box>

      {/* Pull Handle at Bottom */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,        // flush with bottom of viewport
          left: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 1,
          backgroundColor: '#fff',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          boxShadow: '0 -4px 10px rgba(0,0,0,0.3)',
          cursor: 'pointer',
          zIndex: 1000,
        }}
        onClick={() => setDrawerOpen(true)}
      >
        <Box
          sx={{
            width: 40,
            height: 6,
            backgroundColor: 'grey.400',
            borderRadius: 3,
            boxShadow: '0px 2px 4px rgba(0,0,0,0.3)',
            mb: showArrow ? 1 : 0,
          }}
        />
        {showArrow && (
          <Box
            sx={{
              animation: `${bounceAnimation} 2s infinite`,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <KeyboardArrowUpIcon />
          </Box>
        )}
      </Box>

      {/* Swipeable Drawer with Customization Form */}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        swipeAreaWidth={50}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: '16px 16px 0 0',
            height: '80vh',
            overflow: 'auto',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <Box sx={{ width: 40, height: 6, backgroundColor: 'grey.400', borderRadius: 3 }} />
        </Box>
        <Box sx={{ p: 2, overflowY: 'auto', height: 'calc(100% - 30px)' }}>
          <EventCustomizationForm
            eventDetails={eventDetails}
            initialData={customizationData}
            editMode={editMode}
            hidePreviewOnMobile={true}
            onNext={(updatedData) => {
              setCustomizationData(updatedData);
              setStep(3);
            }}
            onBack={() => setDrawerOpen(false)}
            onChange={(updatedData) => {
              setCustomizationData(updatedData);
            }}
          />
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default MobileCustomization;
