import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Button, Box, Card, CardMedia } from '@mui/material';
import { db } from '../firebase/firebaseConfig';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import uploadMedia from '../firebase/uploadMedia';
import SwipeableViews from 'react-swipeable-views';
import { useSwipeable } from 'react-swipeable';
import Resizer from 'react-image-file-resizer';
import { fonts } from '../utils/fontsandthemes';
import '../styles/EventPage.css';
import { getAuth, signInAnonymously } from "firebase/auth";
import { deleteMedia } from '../services/mediaService';

// Define guest limits based on the new plan price IDs
const guestLimits = {
  'price_1R85uFRtiroMBcDI97u58KNs': 15,  // Event Flash: Up to 15 guests
  'price_1QftFXRtiroMBcDIFhy8TSYQ': 35,  // Event Glow-Up: Up to 35 guests
  'price_1R85VSRtiroMBcDIXv9u0P6q': 60,  // Social Spark: Up to 60 guests
  'price_1R85YsRtiroMBcDIi5iHkGUb': 100, // VIP Experience: Up to 100 guests
  'price_1R85wBRtiroMBcDIf4HpIbr7': 250  // Premium: Up to 250 guests
};

const EventPage = () => { 
  const { uniqueUrl } = useParams();
  const [eventData, setEventData] = useState(null);
  const [media, setMedia] = useState({ photos: [], videos: [] });
  // Initially set backgroundColor to white; will update when eventData loads.
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [openPhotoViewer, setOpenPhotoViewer] = useState(false);
  const [index, setIndex] = useState(0);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  // Listen for auth changes (to determine event planner privileges)
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (!currentUser) {
        signInAnonymously(auth)
          .then(() => {
            console.log("Signed in anonymously");
          })
          .catch((error) => {
            console.error("Anonymous sign-in failed:", error);
          });
      } else {
        setUser(currentUser);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth]);

  // Listen to event data changes from Firestore
  useEffect(() => {
    if (!uniqueUrl) return;
    const eventRef = doc(db, "events", uniqueUrl);
    const unsubscribe = onSnapshot(eventRef, (eventSnap) => {
      if (eventSnap.exists()) {
        const data = eventSnap.data();
        setEventData(data);
        setBackgroundColor(data?.customization?.backgroundColor || '#ffffff');
        setMedia({
          photos: data.photos || [],
          videos: data.videos || []
        });
      } else {
        console.log("❌ Event not found in Firestore");
      }
    });
    return () => unsubscribe();
  }, [uniqueUrl]);

  // Check if the current user is the event planner
  const isEventPlanner = () => user && eventData && user.uid === eventData.userId;

  // Resize image helper function
  const resizeImage = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        'JPEG',
        80,
        0,
        (uri) => resolve(uri),
        'file'
      );
    });
  };

  // Media upload handler with guest contribution limit check
  const handleMediaUpload = async (e) => {
    const files = Array.from(e.target.files);
    
    // If user is a guest (not the event planner), check guest contribution limit.
    // Assumes eventData contains a field 'planPriceId' with the current plan's price ID.
    if (!isEventPlanner()) {
      const guestContributors = eventData.guestContributors || [];
      const planPriceId = eventData.planPriceId;
      const allowedGuests = guestLimits[planPriceId] || 0;
      if (!guestContributors.includes(user.uid)) {
        if (guestContributors.length >= allowedGuests) {
          alert(`Guest contribution limit reached (${allowedGuests} guests allowed).`);
          return;
        } else {
          const eventRef = doc(db, "events", uniqueUrl);
          await updateDoc(eventRef, {
            guestContributors: [...guestContributors, user.uid]
          });
          setEventData(prev => ({
            ...prev,
            guestContributors: [...guestContributors, user.uid]
          }));
        }
      }
    }

    try {
      const uploadedMediaUrls = await Promise.all(
        files.map(async (file) => {
          const mediaToUpload = file.type.startsWith('image/') ? await resizeImage(file) : file;
          const uploadedUrl = await uploadMedia(uniqueUrl, mediaToUpload);
          return { url: uploadedUrl, type: file.type.startsWith('image/') ? 'photo' : 'video' };
        })
      );
      const newPhotos = uploadedMediaUrls.filter((item) => item.type === 'photo').map((item) => item.url);
      const newVideos = uploadedMediaUrls.filter((item) => item.type === 'video').map((item) => item.url);
      const eventRef = doc(db, "events", uniqueUrl);
      await updateDoc(eventRef, {
        photos: [...(eventData?.photos || []), ...newPhotos],
        videos: [...(eventData?.videos || []), ...newVideos]
      });
    } catch (error) {
      console.error('Error uploading media:', error);
    }
  };

  // Photo click handler for full-screen viewer
  const handlePhotoClick = (idx) => {
    setIndex(idx);
    setOpenPhotoViewer(true);
  };

  const handleChangeIndex = (newIndex) => setIndex(newIndex);

  const handlers = useSwipeable({
    onSwipedDown: () => setOpenPhotoViewer(false),
  });

  // Media deletion handler (only for event planner)
  const handleDelete = async (mediaUrl, mediaType) => {
    if (!isEventPlanner()) {
      console.log("Only event planners can delete media.");
      return;
    }
    try {
      await deleteMedia(uniqueUrl, mediaUrl, mediaType, user.uid, eventData.userId);
      setMedia((prevMedia) => ({
        ...prevMedia,
        [mediaType === 'photo' ? 'photos' : 'videos']: prevMedia[mediaType === 'photo' ? 'photos' : 'videos'].filter((url) => url !== mediaUrl)
      }));
    } catch (error) {
      console.error('Error deleting media:', error);
    }
  };

  // Use customization values from eventData if available
  const customization = eventData?.customization || {};
  const containerStyles = {
    fontFamily: customization.titleFont || 'Arial',
    fontSize: customization.titleFontSize || '20px',
    color: customization.titleFontColor || '#000',
    padding: '20px',
    minHeight: '100vh'
  };

  const titleStyles = {
    fontFamily: customization.titleFont || 'Arial',
    fontSize: customization.titleFontSize || '20px',
    color: customization.titleFontColor || '#000',
    textShadow: customization.titleShadow || 'none',
    fontWeight: customization.titleBold ? 'bold' : 'normal',
    textAlign: 'center',
    marginBottom: '10px'
  };

  const dateStyles = {
    fontFamily: customization.dateFont || 'Arial',
    fontSize: customization.dateFontSize || '14px',
    color: customization.dateFontColor || '#000',
    fontWeight: customization.dateBold ? 'bold' : 'normal',
    textAlign: 'center',
    marginBottom: '10px'
  };

  const welcomeStyles = {
    fontFamily: customization.welcomeFont || 'Arial',
    fontSize: customization.welcomeFontSize || '16px',
    color: customization.welcomeFontColor || '#000',
    fontWeight: customization.welcomeBold ? 'bold' : 'normal',
    textAlign: 'center',
    marginBottom: '20px'
  };

  const showTitle = customization.showTitle !== undefined ? customization.showTitle : true;
  const showDate = customization.showDate !== undefined ? customization.showDate : true;
  const showWelcomeMessage = customization.showWelcomeMessage !== undefined ? customization.showWelcomeMessage : true;
  const showButton = customization.showButton !== undefined ? customization.showButton : true;

  const gridStyles = eventData?.gridStyle?.styles || {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '16px'
  };

  const combinedMedia = [
    ...media.photos.map((url) => ({ url, type: 'photo' })),
    ...media.videos.map((url) => ({ url, type: 'video' }))
  ];

  const renderMediaGrid = () => (
    <div style={{ ...gridStyles, display: 'grid' }}>
      {combinedMedia.map((item, idx) => (
        <Card key={idx} style={{ margin: '16px' }} onClick={() => item.type === 'photo' && handlePhotoClick(idx)}>
          {item.type === 'photo' ? (
            <CardMedia component="img" image={item.url} alt={`Media ${idx}`} />
          ) : (
            <CardMedia component="video" controls src={item.url} alt={`Media ${idx}`} />
          )}
          {!loading && isEventPlanner() && (
            <Button variant="contained" color="error" onClick={() => handleDelete(item.url, item.type)}>
              Delete
            </Button>
          )}
        </Card>
      ))}
    </div>
  );

  // Show a simple loading UI if eventData isn't loaded yet
  if (!eventData) {
    return (
      <div style={{ backgroundColor: backgroundColor, minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6">Loading...</Typography>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: backgroundColor, minHeight: '100vh' }}>
      <Container style={containerStyles}>
        <div className="event-header">
          {customization.coverPhotoUrl && (
            <div className="cover-photo-container">
              <img src={customization.coverPhotoUrl} alt="Cover" className="cover-photo" />
            </div>
          )}
          {showTitle && (
            <Typography variant="h4" component="h1" className="event-title" style={titleStyles}>
              {eventData?.details?.eventName || 'Untitled Event'}
            </Typography>
          )}
          {showDate && (
            <Typography variant="subtitle1" className="event-date" style={dateStyles}>
              {eventData?.details?.eventDate}
            </Typography>
          )}
          {showWelcomeMessage && eventData?.details?.welcomeMessage && (
            <Typography variant="h6" component="h2" className="italic-message" style={welcomeStyles}>
              {eventData.details.welcomeMessage}
            </Typography>
          )}
        </div>
        {showButton && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <input
              type="file"
              accept="image/*,video/*"
              multiple
              onChange={handleMediaUpload}
              style={{ display: 'none' }}
              id="media-upload"
            />
            <Button
              variant="contained"
              style={{
                backgroundColor: customization.buttonColor || '#1976d2',
                boxShadow: customization.buttonGlow || 'none',
                color: '#fff',
                padding: '10px 20px',
                borderRadius: '8px'
              }}
              onClick={() => document.getElementById('media-upload').click()}
            >
              Upload Photos/Videos
            </Button>
          </Box>
        )}
        <div className="media-gallery" style={{ marginTop: '30px' }}>
          {renderMediaGrid()}
        </div>
        {openPhotoViewer && (
          <div
            {...handlers}
            onClick={(e) => { if (e.target === e.currentTarget) setOpenPhotoViewer(false); }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 999
            }}
          >
            <button
              onClick={() => setOpenPhotoViewer(false)}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                fontSize: '20px',
                border: 'none',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
              }}
            >
              X
            </button>
            <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
              {media.photos.map((photo, idx) => (
                <div
                  key={idx}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                    height: '100vh',
                    overflow: 'hidden'
                  }}
                >
                  <img
                    src={photo}
                    alt={`Photo ${idx}`}
                    style={{
                      maxWidth: '90%',
                      maxHeight: '90%',
                      objectFit: 'contain',
                      borderRadius: '10px'
                    }}
                  />
                </div>
              ))}
            </SwipeableViews>
          </div>
        )}
      </Container>
    </div>
  );
};

export default EventPage;
