// EmailVerificationWaiting.js
import { useEffect, useState } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Box, Typography, CircularProgress, Button } from "@mui/material";

const EmailVerificationWaiting = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [resendError, setResendError] = useState(null);

  // Inline Action Code Settings for resending email verification
  const actionCodeSettings = {
    url: "https://elevateevent.co/__auth/action?continueUrl=https://elevateevent.co/dashboard",
    handleCodeInApp: true,
    // Optional: add iOS and android configurations if needed
  };

  useEffect(() => {
    // Start a countdown timer for enabling the "Resend" button
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(countdown);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    // Poll for email verification status every 2 seconds
    const checkVerification = async () => {
      const user = auth.currentUser;
      if (user) {
        // Force a token refresh so that the auth state updates across tabs
        await user.getIdToken(true);
        await user.reload(); // Refresh user data
        if (user.emailVerified) {
          console.log("✅ Email verified! Redirecting...");
          localStorage.removeItem("waitingForVerification");
          navigate("/subpage");
        } else {
          setTimeout(checkVerification, 2000);
        }
      }
    };

    checkVerification();

    return () => clearInterval(countdown);
  }, [navigate, auth]);

  const handleResendEmail = async () => {
    setResendError(null);
    const user = auth.currentUser;

    if (!user) {
      setResendError("No authenticated user found.");
      return;
    }

    try {
      await user.reload();
      // Send the verification email with the inline actionCodeSettings
      await sendEmailVerification(user, actionCodeSettings);
      console.log("✅ Verification email resent with custom action code settings!");
      setCanResend(false);
      setTimer(60);
    } catch (error) {
      console.error("❌ Error resending email:", error.message);
      setResendError(error.message);
    }
  };

  return (
    <Box textAlign="center" mt={4}>
      <Typography variant="body1" color="primary">
        ✅ Waiting for email verification...
      </Typography>
      <Typography variant="body2">
        Once verified, you will be redirected automatically.
      </Typography>
      <CircularProgress size={24} sx={{ marginTop: 2 }} />
      <Typography variant="body2" sx={{ marginTop: 2 }}>
        {canResend ? "Didn't receive the email?" : `Resend available in ${timer}s`}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleResendEmail}
        disabled={!canResend}
        sx={{ marginTop: 2 }}
      >
        Resend Verification Email
      </Button>
      {resendError && (
        <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
          ❌ {resendError}
        </Typography>
      )}
    </Box>
  );
};

export default EmailVerificationWaiting;
