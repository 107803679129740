import React from 'react';
import '../styles/TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Elevate Event Studio Terms and Conditions</h1>
      <p>Effective Date: March 18, 2025</p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to Elevate Event Studio ("we," "us," or "our"). These Terms and Conditions ("Terms")
          govern your access to and use of our website, mobile applications, and services (collectively,
          the "Services"). By accessing or using the Services, you agree to be bound by these Terms. If you
          do not agree to these Terms, do not access or use the Services.
        </p>
      </section>

      <section>
        <h2>2. Services Description</h2>
        <p>
          Elevate Event Studio provides a platform for users to create, customize, and manage photo-sharing events. 
          Users can create events, generate unique links and QR codes for sharing, and invite attendees to upload 
          photos to designated events. We offer various subscription packages that provide different features, event 
          limits, and photo storage durations.
        </p>
      </section>

      <section>
        <h2>3. User Accounts</h2>
        <ul>
          <li>
            <strong>Account Creation:</strong> You must create an account to use the Services. You agree to provide 
            accurate and complete information when creating your account. You are responsible for maintaining 
            the confidentiality of your account credentials, including your password. You must not share your 
            account with anyone else.
          </li>
          <li>
            <strong>Account Responsibility:</strong> You are responsible for all activities that occur under your 
            account, whether or not you authorized them. Notify us immediately of any unauthorized access or 
            suspected security breaches.
          </li>
          <li>
            <strong>Account Termination:</strong> We may suspend or terminate your account at our discretion, with 
            or without notice, for violations of these Terms or for any other reason, including but not limited 
            to inactivity or suspected fraudulent activity. Upon termination, you will no longer have access to 
            your account or any content associated with it.
          </li>
        </ul>
      </section>

      <section>
        <h2>4. User Content</h2>
        <ul>
          <li>
            <strong>Content Ownership:</strong> You retain ownership of the photos you upload to the Services.
          </li>
          <li>
            <strong>Content License:</strong> By uploading photos, you grant Elevate Event Studio a non-exclusive, 
            worldwide, royalty-free license to use, reproduce, distribute, and display the photos solely for the 
            purpose of providing the Services.
          </li>
          <li>
            <strong>Prohibited Content:</strong> You agree not to upload any content that is unlawful, harmful, 
            threatening, abusive, defamatory, vulgar, obscene, invasive of another’s privacy, hateful, or otherwise 
            objectionable.
          </li>
          <li>
            <strong>Content Removal:</strong> We may remove any content that violates these Terms at our discretion.
          </li>
        </ul>
      </section>

      <section>
        <h2>5. Payment and Subscriptions</h2>
        <ul>
          <li>
            <strong>Payment:</strong> You agree to pay all fees associated with your chosen payment plan. 
            Payments are processed securely through Stripe. By using our Services, you agree to Stripe’s terms of 
            service and privacy policy.
          </li>
          <li>
            <strong>Card Information:</strong> Elevate Event Studio does not store your credit card details. All 
            payments are processed securely through Stripe.
          </li>
          <li>
            <strong>No Auto-Renewal:</strong> Your subscription will not automatically renew. You will need to 
            manually renew your subscription at the end of your current term.
          </li>
          <li>
            <strong>No Refunds:</strong> All fees are non-refundable.
          </li>
        </ul>
      </section>

      <section>
        <h2>6. Intellectual Property</h2>
        <ul>
          <li>
            <strong>Our Content:</strong> All content on the Services, including but not limited to text, graphics, 
            logos, and software, is the property of Elevate Event Studio.
          </li>
          <li>
            <strong>Restrictions:</strong> You may not use any content from the Services without our prior written consent.
          </li>
        </ul>
      </section>

      <section>
        <h2>7. Disclaimer of Warranties</h2>
        <p>
          THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS 
          OR IMPLIED. WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE.
        </p>
      </section>

      <section>
        <h2>8. Limitation of Liability</h2>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, 
          CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES.
        </p>
      </section>

      <section>
        <h2>9. Governing Law</h2>
        <p>
          These Terms will be governed by and construed in accordance with the applicable laws of the United States, 
          without regard to its conflict of law principles.
        </p>
      </section>

      <section>
        <h2>11. Changes to Terms</h2>
        <p>
          We may update these Terms at any time. We will notify you of any material changes. Your continued use of 
          the Services after the changes are effective constitutes your acceptance of the revised Terms.
        </p>
      </section>

      <section>
        <h2>12. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at: support@elevateevent.co.
        </p>
      </section>

      <section>
        <h2>13. Third-Party Services</h2>
        <p>
          Our Services integrate with third-party services, including Stripe for payment processing. Your use of these 
          third-party services is subject to their respective terms and conditions and privacy policies.
        </p>
      </section>

      <section>
        <h2>14. Data Security</h2>
        <p>
          We take reasonable measures to protect the security of your data, but we cannot guarantee absolute security. 
          You are responsible for maintaining the confidentiality of your account credentials.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
