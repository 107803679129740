// SignUp.js
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  sendEmailVerification, 
  setPersistence, 
  browserLocalPersistence 
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig"; // Adjust the path as needed
import InfoIcon from "@mui/icons-material/Info";
import EmailVerificationWaiting from "./EmailVerificationWaiting"; // Adjust the path as needed

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // Check localStorage flag to see if we are already waiting for verification
  const [isVerifying, setIsVerifying] = useState(
    localStorage.getItem("waitingForVerification") === "true"
  );
  
  const navigate = useNavigate();

  // Inline Action Code Settings for email verification
  const actionCodeSettings = {
    url: "https://elevateevent.co/__auth/action?continueUrl=https://elevateevent.co/dashboard",
    handleCodeInApp: true,
    // Optionally add iOS or android configurations if needed
    // iOS: { bundleId: "com.example.ios" },
    // android: { packageName: "com.example.android", installApp: true, minimumVersion: "12" },
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (isVerifying) return;
  
    localStorage.setItem("waitingForVerification", "true");
  
    try {
      const auth = getAuth();
      await setPersistence(auth, browserLocalPersistence);
  
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      if (!user?.uid) throw new Error("Error: User UID is missing.");
  
      console.log("User created:", user.uid);
  
      // Send verification email using inline actionCodeSettings
      await sendEmailVerification(user, actionCodeSettings);
      console.log("Verification email sent with custom action code settings!");
  
      // Create a Firestore document for the user
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        eventCount: 0,
        stripeId: "",
        subscriptionStatus: "free_trial",
      });
  
      console.log("User document written successfully!");
  
      // Optional delay to let changes propagate
      await new Promise((resolve) => setTimeout(resolve, 800));
  
      setIsVerifying(true); // Transition to verification waiting UI
  
    } catch (error) {
      console.error("Signup failed:", error);
      alert("Error signing up: " + error.message);
      localStorage.removeItem("waitingForVerification");
    }
  };
  
  if (isVerifying) {
    return <EmailVerificationWaiting />;
  }

  return (
    <Container maxWidth="sm" sx={{ marginTop: { xs: "80px", md: "100px" }, paddingBottom: "20px" }}>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "50px" }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Sign Up
          </Typography>

          <form onSubmit={handleSignUp} style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tooltip title="Enter a valid email address" arrow>
                  <TextField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                    disabled={isVerifying}
                    inputProps={{ maxLength: 254 }} // Character limit added
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="Password should be at least 8 characters long and include a number and a special character" arrow>
                  <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                    disabled={isVerifying}
                    inputProps={{ maxLength: 128 }} // Character limit added
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={isVerifying}
                  sx={{
                    background: isVerifying ? "#ccc" : "linear-gradient(90deg, #2A73E8 0%, #1A5BBE 100%)",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    padding: "12px 16px",
                    borderRadius: "8px",
                    "&:hover": {
                      background: isVerifying ? "#ccc" : "linear-gradient(90deg, #1A5BBE 0%, #12459A 100%)",
                      boxShadow: isVerifying ? "none" : "0 4px 15px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  {isVerifying ? "Verifying..." : "Sign Up"}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" align="center">
                  By proceeding, you are agreeing to the{" "}
                  <Link to="/terms" target="_blank" rel="noopener noreferrer">
                    Terms and Conditions
                  </Link>
                  .
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Container>
  );
};

export default SignUp;
