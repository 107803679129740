// Benefits.jsx
import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Benefits = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#000', // Black background outside the card
        padding: '40px 0', // Add spacing for the container
      }}
    >
      <Container>
        <Box
          sx={{
            backgroundColor: '#E0E0E0', // Darker grey background for the card
            padding: '40px',
            borderRadius: '8px',
            textAlign: 'left',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            Capture Every Moment Instantly
          </Typography>
          <Typography paragraph>
            Whether it's a wedding, birthday, graduation, baby shower, or any celebration, our app makes it effortless to create live galleries that showcase the memories of your special day.
          </Typography>

          <List>
            <ListItem>
              <ListItemIcon>
                <Box
                  sx={{
                    backgroundColor: '#000', // Black circle
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                  }}
                >
                  <CheckCircleIcon sx={{ color: '#D4AF37', fontSize: 24 }} /> {/* Gold check */}
                </Box>
              </ListItemIcon>
              <ListItemText primary="Create instant live galleries for any celebration and relive the moments as they happen." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Box
                  sx={{
                    backgroundColor: '#000', // Black circle
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                  }}
                >
                  <CheckCircleIcon sx={{ color: '#D4AF37', fontSize: 24 }} />
                </Box>
              </ListItemIcon>
              <ListItemText primary="Effortlessly collect photos and videos without the need for logins or complicated setups." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Box
                  sx={{
                    backgroundColor: '#000', 
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                  }}
                >
                  <CheckCircleIcon sx={{ color: '#D4AF37', fontSize: 24 }} />
                </Box>
              </ListItemIcon>
              <ListItemText primary="Share your live gallery with friends and family instantly, making every event more interactive." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Box
                  sx={{
                    backgroundColor: '#000', 
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                  }}
                >
                  <CheckCircleIcon sx={{ color: '#D4AF37', fontSize: 24 }} />
                </Box>
              </ListItemIcon>
              <ListItemText primary="Preserve memories from all your milestones with a seamless, hassle-free experience." />
            </ListItem>
          </List>
        </Box>
      </Container>
    </Box>
  );
};

export default Benefits;
