import React from 'react';
import { Typography, Box, Container, Divider, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CheckCircle, Save, UploadFile, QrCode2, HelpOutline } from '@mui/icons-material';

const Docs = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ borderRadius: 4, overflow: 'hidden' }}>
        {/* Header Section */}
        <Box
          sx={{
            background: 'linear-gradient(to right, #4a90e2, #98c93c)',
            padding: '40px 20px',
            textAlign: 'center',
            color: '#fff',
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
            Create and Manage Your Event Galleries
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, opacity: 0.9 }}>
            Everything you need to make sharing memories simple and seamless.
          </Typography>
        </Box>

        {/* Main Content */}
        <Box sx={{ padding: '30px' }}>
          {/* Media Management */}
          <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <CheckCircle sx={{ color: '#4caf50', mr: 1 }} /> Manage Uploaded Media
            </Typography>
            <Typography variant="body1">
              You have full control over the photos and videos uploaded by participants. Whether you want to
              organize, review, or delete media, the tools are at your fingertips to keep your gallery looking its best.
            </Typography>
          </Paper>

          {/* Saving Memories */}
          <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <Save sx={{ color: '#2196f3', mr: 1 }} /> Saving Memories
            </Typography>
            <Typography variant="body1">
              Download your favorite photos and videos directly to your device. With just a tap, you can save
              cherished memories to revisit anytime.
            </Typography>
          </Paper>

          {/* Video Upload Guidelines */}
          <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <UploadFile sx={{ color: '#f57c00', mr: 1 }} /> Video Upload Guidelines
            </Typography>
            <Typography variant="body1">
              To ensure smooth performance, videos can be up to <strong>10 seconds</strong> long. This allows
              everyone to share highlights without interruptions.
            </Typography>
          </Paper>

          {/* Creating a Gallery */}
          <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <QrCode2 sx={{ color: '#673ab7', mr: 1 }} /> Setting Up Your Event Gallery
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Follow these simple steps to create and share your event gallery:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircle sx={{ color: '#4caf50' }} />
                </ListItemIcon>
                <ListItemText primary="1. Create Gallery: Enter the event name, date, and a welcome message." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircle sx={{ color: '#4caf50' }} />
                </ListItemIcon>
                <ListItemText primary="2. Customize: Add a cover photo and select a theme that suits your style." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircle sx={{ color: '#4caf50' }} />
                </ListItemIcon>
                <ListItemText primary="3. Share: Generate a QR code or direct link for easy sharing." />
              </ListItem>
            </List>
          </Paper>

          {/* QR Code Sharing */}
          <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}
            >
              <QrCode2 sx={{ color: '#009688', mr: 1 }} /> Sharing Your Gallery
            </Typography>
            <Typography variant="body1">
              Share your event gallery in just a few clicks:
            </Typography>
            <ul style={{ marginLeft: '20px' }}>
              <li>Download the QR code as an image or PDF.</li>
              <li>Use it on table cards, posters, or invitations.</li>
              <li>Allow participants to upload photos instantly without needing an account.</li>
            </ul>
          </Paper>

          {/* Need Help */}
          <Paper elevation={2} sx={{ p: 3, borderRadius: 2, textAlign: 'center' }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', mb: 2, display: 'flex', justifyContent: 'center' }}
            >
              <HelpOutline sx={{ color: '#ff5722', mr: 1 }} /> Need Help?
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              If you have any questions, contact us at <strong>support@elevateevent.co</strong>.
            </Typography>
          </Paper>
        </Box>
      </Paper>
    </Container>
  );
};

export default Docs;
