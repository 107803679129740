import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  LinearProgress,
  Button
} from '@mui/material';
import { doc, getDoc, setDoc, updateDoc, collection } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import EventDetailsForm from './EventDetailsForm';
import EventCustomizationForm from './EventCustomizationForm';
import EventPreviewForm from './EventPreviewForm';
import MobileCustomization from './MobileCustomization';
import { fonts } from '../utils/fontsandthemes';
import { QrCode } from '@mui/icons-material'; // For the purple QR code icon

const EventCreationForm = ({ editMode }) => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const isEditing = editMode && eventId;
  const [step, setStep] = useState(1);
  const [eventDetails, setEventDetails] = useState({});
  const [customizationData, setCustomizationData] = useState({});
  const [loading, setLoading] = useState(editMode);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const containerRef = useRef(null);

  // Resize listener for mobile detection
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch event data or apply defaults
  useEffect(() => {
    if (isEditing) {
      // Edit mode
      const fetchEventData = async () => {
        try {
          const eventRef = doc(db, 'events', eventId);
          const eventSnap = await getDoc(eventRef);
          if (eventSnap.exists()) {
            const eventData = eventSnap.data();
            setEventDetails(eventData.details || {});
            setCustomizationData({
              ...eventData.customization,
              backgroundColor: eventData.customization?.backgroundColor || '#ffffff',
            });
          } else {
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching event data:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchEventData();
    } else {
      // New event defaults
      setEventDetails({});
      setCustomizationData({
        backgroundColor: '#ffffff',
        coverPhotoUrl: 'https://via.placeholder.com/150',
        titleFont: fonts[0].name,
        titleFontSize: '20px',
        titleFontColor: '#000000',
        titleBold: false,
        titleShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
        titleGlow: '0px 0px 10px rgba(255, 255, 255, 0.75)',
        dateFont: fonts[0].name,
        dateFontSize: '14px',
        dateFontColor: '#000000',
        dateBold: false,
        welcomeFont: fonts[0].name,
        welcomeFontSize: '16px',
        welcomeFontColor: '#000000',
        welcomeBold: false,
        buttonColor: '#1976d2',
        buttonGlow: '0px 0px 15px rgba(0, 122, 255, 0.6)',
        buttonGlowColor: '#007aff',
        glowIntensity: 15,
      });
      setLoading(false);
    }
  }, [isEditing, eventId]);

  const handleSaveEvent = async () => {
    if (!eventDetails || !customizationData) {
      console.error('Event details are missing');
      return;
    }

    const eventRef = doc(db, 'events', eventId || doc(collection(db, 'events')).id);

    try {
      if (isEditing) {
        await updateDoc(eventRef, {
          details: eventDetails,
          customization: customizationData,
        });
        console.log('Event updated successfully!');
      } else {
        await setDoc(eventRef, {
          details: eventDetails,
          customization: customizationData,
        });
        console.log('Event created successfully!');
      }

      navigate('/eventdashboard');
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const progressValue = loading ? 0 : step === 1 ? 33 : step === 2 ? 66 : 100;

  // --- MOBILE LAYOUT ---
  if (isMobile) {
    return (
      <Box sx={{ px: 2, pt: 2 }} ref={containerRef}>
        <Box sx={{ mb: 3 }}>
          <LinearProgress
            variant="determinate"
            value={loading ? 0 : progressValue}
            sx={{
              height: 6,
              borderRadius: 5,
              backgroundColor: '#e0e0e0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: isEditing ? '#FFA726' : '#4CAF50',
              },
            }}
          />
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: 'bold',
              mb: 3,
              textAlign: 'center',
              fontSize: '1.5rem',
              color: isEditing ? '#FF9800' : '#333',
            }}
          >
            {step === 1
              ? isEditing
                ? 'Edit Event - Step 1'
                : 'Create Event - Step 1'
              : step === 2
              ? isEditing
                ? (
                  <>
                    Edit Event - Step 2{' '}
                    <Box component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      <Box
                        component="svg"
                        sx={{ width: 18, height: 18 }}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fill="#D2B48C"
                          d="M12 2C7.03 2 2.5 5.53 2.5 10.5S7.03 19 12 19c.5 0 .99-.04 1.48-.12l.63.14.55.82c.54.81 1.47 1.3 2.43 1.3.83 0 1.5-.67 1.5-1.5V16c0-.55.45-1 1-1h.5c3.04 0 5.5-2.46 5.5-5.5C21.5 5.03 17.52 2 12 2z"
                        />
                        <circle cx="6.5" cy="8" r="1" fill="#FF0000" />
                        <circle cx="10.5" cy="6" r="1" fill="#00FF00" />
                        <circle cx="14.5" cy="6" r="1" fill="#0000FF" />
                        <circle cx="18.5" cy="8" r="1" fill="#FFFF00" />
                      </Box>
                    </Box>
                  </>
                )
                : (
                  <>
                    Customize Event - Step 2{' '}
                    <Box component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                      <Box
                        component="svg"
                        sx={{ width: 18, height: 18 }}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fill="#D2B48C"
                          d="M12 2C7.03 2 2.5 5.53 2.5 10.5S7.03 19 12 19c.5 0 .99-.04 1.48-.12l.63.14.55.82c.54.81 1.47 1.3 2.43 1.3.83 0 1.5-.67 1.5-1.5V16c0-.55.45-1 1-1h.5c3.04 0 5.5-2.46 5.5-5.5C21.5 5.03 17.52 2 12 2z"
                        />
                        <circle cx="6.5" cy="8" r="1" fill="#FF0000" />
                        <circle cx="10.5" cy="6" r="1" fill="#00FF00" />
                        <circle cx="14.5" cy="6" r="1" fill="#0000FF" />
                        <circle cx="18.5" cy="8" r="1" fill="#FFFF00" />
                      </Box>
                    </Box>
                  </>
                )
              : isEditing
              ? 'Edit Event - Step 3'
              : 'Share Event - Step 3'}
          </Typography>

          {loading ? (
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Typography variant="h6">Loading event data...</Typography>
            </Box>
          ) : (
            <>
              {step === 1 && (
                <>
                  {/* --- ALIGNED TEXT FOR STEP 1 (MOBILE) --- */}
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 1,
                      textAlign: 'center',
                      color: '#666',
                      lineHeight: 1.4,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Box sx={{ mb: 0.5 }}>
                        <strong style={{ fontSize: '1.2rem', color: '#000' }}>
                          Step 1: Event Overview
                        </strong>
                      </Box>
                      <Box sx={{ mb: 0.5 }}>
                        <strong style={{ fontSize: '1.1rem', color: '#000' }}>Step 2</strong>{' '}
                        <Box component="span" sx={{ verticalAlign: 'middle' }}>
                          <Box
                            component="svg"
                            sx={{ width: 18, height: 18, ml: 0.5 }}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fill="#D2B48C"
                              d="M12 2C7.03 2 2.5 5.53 2.5 10.5S7.03 19 12 19c.5 0 .99-.04 1.48-.12l.63.14.55.82c.54.81 1.47 1.3 2.43 1.3.83 0 1.5-.67 1.5-1.5V16c0-.55.45-1 1-1h.5c3.04 0 5.5-2.46 5.5-5.5C21.5 5.03 17.52 2 12 2z"
                            />
                            <circle cx="6.5" cy="8" r="1" fill="#FF0000" />
                            <circle cx="10.5" cy="6" r="1" fill="#00FF00" />
                            <circle cx="14.5" cy="6" r="1" fill="#0000FF" />
                            <circle cx="18.5" cy="8" r="1" fill="#FFFF00" />
                          </Box>
                        </Box>
                        : Customize gallery
                      </Box>
                      <Box>
                        <strong style={{ fontSize: '1.1rem', color: '#000' }}>Step 3</strong>{' '}
                        <Box component="span" sx={{ verticalAlign: 'middle', ml: 0.5 }}>
                          <QrCode sx={{ color: 'purple', fontSize: '18px' }} />
                        </Box>
                        : Get QR code
                      </Box>
                    </Box>
                  </Typography>

                  <EventDetailsForm
                    onNext={(details) => {
                      setEventDetails(details);
                      setStep(2);
                    }}
                    initialData={eventDetails}
                    editMode={editMode}
                  />
                </>
              )}

              {step === 2 && (
                <>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textAlign: 'center',
                      color: '#666',
                      mb: 2,
                      fontWeight: 'bold',
                    }}
                  >
                    You are now in preview mode. Guests will see this online gallery to upload photos.
                  </Typography>
                  <MobileCustomization
                    eventDetails={eventDetails}
                    customizationData={customizationData}
                    setCustomizationData={setCustomizationData}
                    setStep={setStep}
                    editMode={editMode}
                  />
                </>
              )}

              {step === 3 && (
                <>
                  <EventPreviewForm
                    eventDetails={eventDetails}
                    customizationData={customizationData}
                    editMode={editMode}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                    <Button
                      variant="outlined"
                      onClick={() => setStep(2)}
                      sx={{ px: 4, py: 1, fontWeight: 'bold' }}
                    >
                      Back
                    </Button>
                    {isEditing && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveEvent}
                        sx={{
                          px: 4,
                          py: 1,
                          fontWeight: 'bold',
                          backgroundColor: '#FFA726',
                          '&:hover': {
                            backgroundColor: '#FB8C00',
                          },
                        }}
                      >
                        Save Changes
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  }

  // --- DESKTOP LAYOUT ---
  return (
    <Container maxWidth="xl" sx={{ mt: 5, mb: 5, px: { xs: 2, sm: 4, md: 6 } }}>
      <Box sx={{ mb: 3 }}>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : progressValue}
          sx={{
            height: 6,
            borderRadius: 5,
            backgroundColor: '#e0e0e0',
            '& .MuiLinearProgress-bar': {
              backgroundColor: isEditing ? '#FFA726' : '#4CAF50',
            },
          }}
        />
      </Box>

      <Paper
        elevation={3}
        sx={{
          p: { xs: 3, sm: 4, md: 6 },
          mx: 'auto',
          maxWidth: { xs: '100%', sm: '85%', md: '75%' },
          borderRadius: 8,
          backgroundColor: '#ffffff',
          boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.1),
                      0px 8px 16px rgba(0, 0, 0, 0.1)`,
          border: `2px solid ${isEditing ? '#FFA726' : '#ddd'}`,
          mt: { xs: 2, md: isEditing ? 2 : 5 },
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontWeight: 'bold',
            mb: 3,
            textAlign: 'center',
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            color: isEditing ? '#FF9800' : '#333',
          }}
        >
          {step === 1
            ? isEditing
              ? 'Edit Event - Step 1'
              : 'Create Event - Step 1'
            : step === 2
            ? isEditing
              ? (
                <>
                  Edit Event - Step 2{' '}
                  <Box component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                    <Box
                      component="svg"
                      sx={{ width: 18, height: 18 }}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill="#D2B48C"
                        d="M12 2C7.03 2 2.5 5.53 2.5 10.5S7.03 19 12 19c.5 0 .99-.04 1.48-.12l.63.14.55.82c.54.81 1.47 1.3 2.43 1.3.83 0 1.5-.67 1.5-1.5V16c0-.55.45-1 1-1h.5c3.04 0 5.5-2.46 5.5-5.5C21.5 5.03 17.52 2 12 2z"
                      />
                      <circle cx="6.5" cy="8" r="1" fill="#FF0000" />
                      <circle cx="10.5" cy="6" r="1" fill="#00FF00" />
                      <circle cx="14.5" cy="6" r="1" fill="#0000FF" />
                      <circle cx="18.5" cy="8" r="1" fill="#FFFF00" />
                    </Box>
                  </Box>
                </>
              )
              : (
                <>
                  Customize Event - Step 2{' '}
                  <Box component="span" sx={{ verticalAlign: 'middle', ml: 1 }}>
                    <Box
                      component="svg"
                      sx={{ width: 18, height: 18 }}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill="#D2B48C"
                        d="M12 2C7.03 2 2.5 5.53 2.5 10.5S7.03 19 12 19c.5 0 .99-.04 1.48-.12l.63.14.55.82c.54.81 1.47 1.3 2.43 1.3.83 0 1.5-.67 1.5-1.5V16c0-.55.45-1 1-1h.5c3.04 0 5.5-2.46 5.5-5.5C21.5 5.03 17.52 2 12 2z"
                      />
                      <circle cx="6.5" cy="8" r="1" fill="#FF0000" />
                      <circle cx="10.5" cy="6" r="1" fill="#00FF00" />
                      <circle cx="14.5" cy="6" r="1" fill="#0000FF" />
                      <circle cx="18.5" cy="8" r="1" fill="#FFFF00" />
                    </Box>
                  </Box>
                </>
              )
            : isEditing
            ? 'Edit Event - Step 3'
            : 'Share Event - Step 3'}
        </Typography>

        {loading ? (
          <Container maxWidth="xl" sx={{ mt: 5, mb: 5, px: 6, textAlign: 'center' }}>
            <Paper elevation={3} sx={{ p: 6, mx: 'auto', maxWidth: '85%', borderRadius: 12 }}>
              <Typography variant="h6">Loading event data...</Typography>
            </Paper>
          </Container>
        ) : (
          <>
            {step === 1 && (
              <>
                {/* --- ALIGNED TEXT FOR STEP 1 (DESKTOP) --- */}
                <Typography
                  variant="body1"
                  sx={{
                    mb: 1,
                    textAlign: 'center',
                    color: '#666',
                    lineHeight: 1.4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box sx={{ mb: 0.5 }}>
                      <strong style={{ fontSize: '1.2rem', color: '#000' }}>
                        Step 1: Event Overview
                      </strong>
                    </Box>
                    <Box sx={{ mb: 0.5 }}>
                      <strong style={{ fontSize: '1.1rem', color: '#000' }}>Step 2</strong>{' '}
                      <Box component="span" sx={{ verticalAlign: 'middle' }}>
                        <Box
                          component="svg"
                          sx={{ width: 18, height: 18, ml: 0.5 }}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill="#D2B48C"
                            d="M12 2C7.03 2 2.5 5.53 2.5 10.5S7.03 19 12 19c.5 0 .99-.04 1.48-.12l.63.14.55.82c.54.81 1.47 1.3 2.43 1.3.83 0 1.5-.67 1.5-1.5V16c0-.55.45-1 1-1h.5c3.04 0 5.5-2.46 5.5-5.5C21.5 5.03 17.52 2 12 2z"
                          />
                          <circle cx="6.5" cy="8" r="1" fill="#FF0000" />
                          <circle cx="10.5" cy="6" r="1" fill="#00FF00" />
                          <circle cx="14.5" cy="6" r="1" fill="#0000FF" />
                          <circle cx="18.5" cy="8" r="1" fill="#FFFF00" />
                        </Box>
                      </Box>
                      : Customize gallery
                    </Box>
                    <Box>
                      <strong style={{ fontSize: '1.1rem', color: '#000' }}>Step 3</strong>{' '}
                      <Box component="span" sx={{ verticalAlign: 'middle', ml: 0.5 }}>
                        <QrCode sx={{ color: 'purple', fontSize: '18px' }} />
                      </Box>
                      : Get QR code
                    </Box>
                  </Box>
                  <br />
                  <br />
                </Typography>

                <EventDetailsForm
                  onNext={(details) => {
                    setEventDetails(details);
                    setStep(2);
                  }}
                  initialData={eventDetails}
                  editMode={editMode}
                />
              </>
            )}

            {step === 2 && (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: 'center',
                    color: '#666',
                    mb: 2,
                    fontWeight: 'bold',
                  }}
                >
                  You are now in preview mode. Guests will see this online gallery to upload photos.
                </Typography>
                <EventCustomizationForm
                  eventDetails={eventDetails}
                  onNext={(customization) => {
                    setCustomizationData(customization);
                    setStep(3);
                  }}
                  onBack={() => setStep(1)}
                  initialData={customizationData}
                  editMode={editMode}
                />
              </>
            )}

            {step === 3 && (
              <>
                <EventPreviewForm
                  eventDetails={eventDetails}
                  customizationData={customizationData}
                  editMode={editMode}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                  <Button
                    variant="outlined"
                    onClick={() => setStep(2)}
                    sx={{ px: 4, py: 1, fontWeight: 'bold' }}
                  >
                    Back
                  </Button>
                  {isEditing && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveEvent}
                      sx={{
                        px: 4,
                        py: 1,
                        fontWeight: 'bold',
                        backgroundColor: '#FFA726',
                        '&:hover': {
                          backgroundColor: '#FB8C00',
                        },
                      }}
                    >
                      Save Changes
                    </Button>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Paper>
    </Container>
  );
};

export default EventCreationForm; 
